// Variables
//
// Sweco Bootstrap Variables, overrides Bootstrap default values
//

// White
$white:                 #fff;

// Black
$black:                 #000;
$black-ich:             #222;
$black-light:           #3a3a3a;                  // Sweco Text color
$black-lightning:       #444;
$black-lighter:         #555;
$black-ultralight:      #777;
$black-superlight:      #999;

// Sweco Gray Dark
$dark-gray:             #3f3f42;                  // Sweco Gray Dark;
$dark-gray-ich:         lighten($dark-gray, 10%);   // #
$dark-gray-light:       lighten($dark-gray, 20%);   // #
$dark-gray-lighter:     lighten($dark-gray, 30%);   // #
$dark-gray-ultralight:  lighten($dark-gray, 40%);   // #
$dark-gray-superlight:  lighten($dark-gray, 50%);   // #

// Sweco Gray
$gray:                  #a4a4a6;                  // Sweco Gray
$gray-ich:              lighten($gray, 5%);         // #
$gray-light:            lighten($gray, 10%);        // #
$gray-lighter:          lighten($gray, 15%);        // #
$gray-ultralight:       lighten($gray, 20%);        // #
$gray-superlight:       #eee;                     // Sweco Gray Superlight

// Sweco Gray Light
$light-gray:            #e2e0da;                  // Sweco Gray Light
$light-gray-ich:        lighten($light-gray, 2%);   // #
$light-gray-light:      lighten($light-gray, 4%);   // #
$light-gray-lighter:    lighten($light-gray, 6%);   // #
$light-gray-ultralight: lighten($light-gray, 8%);   // #
$light-gray-superlight: lighten($light-gray, 10%);  // #

// Sweco Ochre
$ochre:                 #dec55b;                  // Sweco Ochre Bright
$ochre-dark:            #a4852f;                  // Sweco Ochre Muted
$ochre-ich:             lighten($ochre, 10%);       // #
$ochre-light:           lighten($ochre, 15%);       // #
$ochre-lighter:         lighten($ochre, 20%);       // #
$ochre-ultralight:      lighten($ochre, 25%);       // #

// Sweco Pink
$pink:                  #f2b1dc;                  // Sweco Pink Bright
$pink-darker:           shade($pink, 40%);          // Sweco Pink Contrast
$pink-dark:             #b586a4;                  // Sweco Pink Muted
$pink-ich:              lighten($pink, 6%);         // #
$pink-light:            lighten($pink, 8%);         // #
$pink-lighter:          lighten($pink, 10%);        // #
$pink-ultralight:       lighten($pink, 12%);        // #

// Sweco Blue
$blue:                  #8aa3b9;                  // Sweco Blue Text
$blue-darker:           shade($blue, 26%);          // Sweco Blue Contrast
$blue-dark:             #7e8dab;                  // Sweco Blue Muted
$blue-ich:              lighten($blue, 5%);         // #
$blue-light:            lighten($blue, 10%);        // #
$blue-lighter:          lighten($blue, 15%);        // #
$blue-ultralight:       #c0d4fd;                  // Sweco Blue Bright

// Sweco Green
$green:                 #9dd354;                  // Sweco Green Bright
$green-darker:          shade($green, 25%);         // #
$green-dark:            #708b55;                  // Sweco Green Muted
$green-ich:             lighten($green, 5%);        // #
$green-light:           lighten($green, 10%);       // #
$green-lighter:         lighten($green, 15%);       // #
$green-ultralight:      lighten($green, 20%);       // #

// Red, validation and states
$red:                   #821307;

$primary:       $blue-dark;
$secondary:     $white;
$info:          $ochre;
$success:       $green-dark;   // Validation and states
$warning:       $pink-dark;    // Validation and states
$danger:        $red;          // Validation and states
$light:         $light-gray;
$dark:          $dark-gray;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $ochre,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
), $theme-colors);

// Form states and alerts (Bootstrap 3 scss)
$state-success-text:             $success;
$state-success-bg:               transparent;
$state-success-border:           $success;
$state-primary-text:             $primary;
$state-primary-bg:               transparent;
$state-primary-border:           $primary;
$state-warning-text:             $warning;
$state-warning-bg:               transparent;
$state-warning-border:           $warning;
$state-danger-text:              $danger;
$state-danger-bg:                transparent;
$state-danger-border:            $danger;

// Transparent background
$transparent-black-alpha:        .77;
$transparent-black:              $black-ich;
$transparent-bg:                 rgba($transparent-black,$transparent-black-alpha); // @black-lighter;

// Set a specific jump point for requesting color jumps
$theme-color-interval:      10%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $black-light;
$yiq-text-light: $white;

// Bootstrap options
$enable-caret:              true;
$enable-rounded:            true;
$enable-shadows:            true;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-grid-classes:       true;
$enable-print-styles:       true;

// Spacing 16px
$spacer: 1rem;
$spacer-x: $spacer * 2;
$spacer-y: $spacer * 2;
$spacers: ();
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25), // 4px
  2: ($spacer * .5),  // 8px
  3: $spacer,         // 16px
  4: ($spacer * 1.5), // 24px
  5: ($spacer * 2),   // 32px
  6: ($spacer * 2.5), // 40px
  7: ($spacer * 3),   // 48px
  8: ($spacer * 3.5), // 56px
  9: ($spacer * 4),   // 64px
  10: ($spacer * 4.5),// 72px
  11: ($spacer * 5),  // 80px
  12: ($spacer * 5.5),// 88px
  13: ($spacer * 6),  // 96px
  14: ($spacer * 6.5),// 104px
  15: ($spacer * 7),  // 112px
  16: ($spacer * 7.5),// 120px
  17: ($spacer * 8),  // 128px
  18: ($spacer * 8.5),// 136px
  19: ($spacer * 9),  // 144px
  20: ($spacer * 9.5),// 152px
  21: ($spacer * 10)  // 160px
), $spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
), $sizes);

// Body
$body-bg:                     $gray-superlight;
$body-color:                  $black-light;
$text-color:                  $body-color;
$icon-color:                  $body-color;

// Links
$link-color:                  $pink-darker;
$link-decoration:             none;
$link-hover-color:            $pink-darker;
$link-hover-decoration:       underline;

// Focus
$focus-outline:               $primary solid .125rem; // Content 1px
$focus-outline-offset:        1px;
$focus-box-shadow:            inset 0 0 0 .0625rem $primary; // Forms 1px
$focus-box-shadow-noborder:   inset 0 0 0 .125rem $primary;  // Forms no border 2px
$focus-transition:            border-color .3s ease-in-out,box-shadow .3s ease-in-out;
$focus-opacity:               .5;

// Paragraphs
$paragraph-margin-bottom:     $spacer;

// Grid columns
$grid-columns:                12;
$grid-gutter-width:           32px;
$grid-row-columns:            6;

// Components
$border-width:                1px;
$border-color:                $gray-ultralight;  // #d8d8d8
$border-color-light:          $gray-superlight;

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;

$component-color:                 $text-color;
$component-bg-hover:              $gray-superlight;
$component-active-color:          $white;
$component-active-bg-light:       $border-color-light;
$component-active-hover-bg-light: darken($component-active-bg-light, 5%);
$component-active-bg:             $dark-gray;
$component-active-hover-bg:       darken($component-active-bg, 5%);

$caret-width:                 .3em;

// Transition for btn,  and such
$transition-base:             .3s ease-in-out;
$transition:                  all $transition-base;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .3s ease;
$transition-bg:               background-color $transition-base;
$transition-color:            background-color $transition-base, border-color $transition-base, color $transition-base, box-shadow $transition-base;

// Highlight & shadows
$text-highlight:              0 1px 3px rgba(255,255,255,.7); 
$text-shadow:                 0 1px 3px rgba(0,0,0,.7); 

$box-shadow:                  0 0 2px rgba(0,0,0,.1), 
                              0 0 4px rgba(0,0,0,.1), 
                              0 0 8px rgba(0,0,0,.1), 
                              0 0 16px rgba(0,0,0,.1),
                              0 0 32px rgba(0,0,0,.1);

$box-shadow-sm:               0 0 1px rgba(0,0,0,.1), 
                              0 0 2px rgba(0,0,0,.1), 
                              0 0 4px rgba(0,0,0,.1),
                              0 0 8px rgba(0,0,0,.1);                        

$box-shadow-lg:               0 0 2px rgba(0,0,0,.1), 
                              0 2px 4px rgba(0,0,0,.1), 
                              0 4px 8px rgba(0,0,0,.1), 
                              0 8px 16px rgba(0,0,0,.1),
                              0 16px 32px rgba(0,0,0,.1);

$box-shadow-inset:            inset 0 0 1px rgba(0,0,0,.05), 
                              inset 0 0 2px rgba(0,0,0,.05), 
                              inset 0 0 4px rgba(0,0,0,.05),
                              inset 0 0 8px rgba(0,0,0,.05);

// Cursor
$cursor-disabled:             default;

// Fonts
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-monospace:       Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-regular:         "SwecoSans-Regular", $font-family-sans-serif;
$font-family-medium:          "SwecoSans-Medium", $font-family-sans-serif;
$font-family-heavy:           "SwecoSans-Bold", $font-family-sans-serif;
$font-family-base:            $font-family-regular;

// Font-size for scale all typography
$font-size-base:              1rem;       // 16px
$font-size-xl:                1.5rem;     // 24px
$font-size-lg:                1.25rem;    // 20px
$font-size-sm:                .875rem;    // 14px
$font-size-xs:                .75rem;     // 12px
$icon-font-size:              1.33333rem; // 20px

$line-height-base:            1.5;        // 24px
$line-height-xl:              1.165;      // 28px
$line-height-lg:              1.2;        // 24px
$line-height-sm:              1.425;      // 20px
$line-height-xs:              1.33;       // 16px
$line-height-computed:        1.5rem;     // 24px 

$font-weight-base:            400;
$font-weight-light:           300;
$font-weight-normal:          $font-weight-base;
$font-weight-bold:            700;

$h1-font-size:                $font-size-base * 2.25;  // 36px
$h2-font-size:                $font-size-base * 1.75;  // 28px
$h3-font-size:                $font-size-xl;           // 24px
$h4-font-size:                $font-size-lg;           // 20px
$h5-font-size:                $font-size-base;         // 16px
$h6-font-size:                $font-size-xs;           // 12px

$headings-line-height:        1.25;
$h1-line-height:              $font-size-base * 2.625; // 42px
$h2-line-height:              $font-size-base * 2.25;  // 36px
$h3-line-height:              $font-size-base * 1.75;  // 28px
$h4-line-height:              $line-height-base;       // 24px
$h5-line-height:              $line-height-base;       // 24px
$h6-line-height:              $font-size-base;         // 16px 

$headings-margin-bottom:      $paragraph-margin-bottom;
$headings-font-family:        $font-family-medium;
$headings-font-weight:        $font-weight-normal;
$headings-color:              $black-light;

// Display
$display1-size:               $font-size-base * 4.25;  // 68px
$display2-size:               $font-size-base * 3.75;  // 60px
$display3-size:               $font-size-base * 3.375; // 54px
$display4-size:               $font-size-base * 3;     // 48px

$display1-weight:             $font-weight-light;
$display2-weight:             $font-weight-light;
$display3-weight:             $font-weight-light;
$display4-weight:             $font-weight-light;

$display-line-height:         $headings-line-height;
$line-height-display-1:       1.17;
$line-height-display-2:       1.19;
$line-height-display-3:       1.25;
$line-height-display-4:       1.37;

// Lead
$lead-font-size:              1.25rem;             // 20px
$lead-line-height:            1.2;                 // 24px
$lead-font-weight:            $font-weight-normal;

// Small
$small-font-size:             80%;

// Mute & disabled
$text-muted:                  $gray;
$disabled-color:              $text-muted;
$disabled-bg:                 $gray-superlight;

// Abbr
$abbr-border-color:           $gray;

$blockquote-color:            $text-color;
$blockquote-small-color:      $text-muted;
$blockquote-font-size:        $font-size-lg;

// Hr
$hr-border-color:             $border-color;
$hr-border-width:             $border-width;
$hr-margin-y:                 $paragraph-margin-bottom;

// Mark
$mark-padding:                .2em .1em;
$mark-bg:                     $light-gray;

// Kbd
$kbd-color:                   $white;
$kbd-bg:                      $black-lighter;
$kbd-box-shadow:              none;
$nested-kbd-font-weight:      normal;

// Lists
$dt-font-weight:              normal;
$dt-font-weight:              $font-weight-bold;
$list-inline-padding:         .5rem;

// Tables
$table-cell-padding:          ($spacer * .249) ($spacer * .5) ($spacer * .25);
$table-cell-padding-sm:       ($spacer * .15) ($spacer * .5);

$table-bg:                    transparent;
$table-accent-bg:             $light-gray-superlight;
$table-hover-bg:              $component-bg-hover;
$table-active-bg:             $gray-superlight;

$table-border-width:          $border-width;
$table-border-color:          $border-color;

$table-head-font-family:      $font-family-heavy;
$table-head-bg:               $table-bg;
$table-head-color:            $headings-color;

// Buttons + Forms
$input-btn-focus-width:       0;
$input-btn-focus-color:       rgba($component-active-bg, .25);
$input-btn-focus-box-shadow:  none;

$input-font-family:           $font-family-base;

$input-btn-font-size:         $font-size-base;    // 16px
$input-btn-line-height:       $line-height-base;  // 24px
$input-btn-padding-y:         .44rem;             // Height 40px
$input-btn-padding-x:         $spacer * .75;

$input-btn-font-size-lg:      $font-size-lg;      // 20px
$input-btn-line-height-lg:    $line-height-lg;    // 24px
$input-btn-padding-y-lg:      .69rem;             // Height 48px
$input-btn-padding-x-lg:      $spacer * .75;

$input-btn-font-size-sm:      $font-size-sm;      // 14px
$input-btn-line-height-sm:    $line-height-sm;    // 20px
$input-btn-padding-y-sm:      .315rem;            // Height 32px
$input-btn-padding-x-sm:      $spacer * .75;

$input-btn-font-size-xs:      $font-size-xs;      // 12px
$input-btn-line-height-xs:    $line-height-xs;    // 16px
$input-btn-padding-y-xs:      .19rem;             // Height 24px
$input-btn-padding-x-xs:      .25rem;

$input-btn-border-width:      $border-width;

// Buttons
$btn-padding-y:               $input-btn-padding-y + .06rem;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;

$btn-padding-y-xs:            $input-btn-padding-y-xs;
$btn-padding-x-xs:            $input-btn-padding-x-xs;
$btn-font-size-xs:            $input-btn-font-size-xs;
$btn-line-height-xs:          $input-btn-line-height-xs;

$btn-padding-y-sm:            $input-btn-padding-y-sm + .092rem;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-font-size-sm:            $input-btn-font-size-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg + .06rem;
$btn-padding-x-lg:            $input-btn-padding-x-lg;
$btn-font-size-lg:            $input-btn-font-size-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-border-width:            1;

$btn-font-family:             $font-family-medium;
$btn-font-weight:             $font-weight-normal;
$btn-text-transform:          uppercase;
$btn-box-shadow:              none;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;

$btn-disabled-opacity:        1;
$btn-disabled-bg-color:       $disabled-bg;
$btn-link-disabled-color:     $disabled-color;

$btn-block-spacing-y:         .5rem;

$btn-border-radius:           0;
$btn-border-radius-lg:        0;
$btn-border-radius-sm:        0;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-outline-box-width:       400px;

// Btn colors
$btn-primary-color:           $white;
$btn-primary-bg:              $primary;
$btn-primary-border:          $btn-primary-bg;

$btn-secondary-color:         $text-color;
$btn-secondary-bg:            $white;
$btn-secondary-border:        $gray;

$btn-dark-color:              $white;
$btn-dark-bg:                 $dark-gray;
$btn-dark-border:             $btn-dark-bg;

$btn-info-color:              $white;
$btn-info-bg:                 $info;
$btn-info-border:             $btn-info-bg;

$btn-success-color:           $white;
$btn-success-bg:              $success;
$btn-success-border:          $btn-success-bg;

$btn-warning-color:           $white;
$btn-warning-bg:              $warning;
$btn-warning-border:          $btn-warning-bg;

$btn-danger-color:            $white;
$btn-danger-bg:               $danger;
$btn-danger-border:           $btn-danger-bg;

// Fieldset
$fieldset-bg:                           $light-gray;
$fieldset-padding:                      $spacer * 2;
$fieldset-padding-sm:                   $spacer;

// Forms
$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-xs:                    $input-btn-padding-y-xs;
$input-padding-x-xs:                    $input-btn-padding-x-xs;
$input-font-size-xs:                    $input-btn-font-size-xs;
$input-line-height-xs:                  $input-btn-line-height-xs;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-bg:                              $white;
$input-disabled-bg:                     $disabled-bg;

$input-color:                           $text-color;
$input-border-color:                    $gray-light;
$input-border-width:                    $input-btn-border-width;
$input-box-shadow:                      none;

$input-border-radius:                   0;
$input-border-radius-lg:                0;
$input-border-radius-sm:                0;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $primary;
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $focus-box-shadow;

$input-placeholder-color:               $dark-gray;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2);

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-xs:                       add($input-line-height-xs * 1em, add($input-padding-y-xs * 2, $input-height-border, false));
$input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top:                  .25rem;

$form-check-input-gutter:               1.5rem;
$form-check-input-margin-y:             .3rem;
$form-check-input-margin-x:             .25rem;

$form-check-inline-margin-x:            .75rem;
$form-check-inline-input-margin-x:      .3125rem;

$form-group-margin-bottom:              $spacer;

$input-group-addon-color:               $white;
$input-group-addon-bg:                  $gray;
$input-group-addon-border-color:        $input-border-color;

// Custom checkbox
$custom-control-gutter:                         $spacer * .7;
$custom-control-spacer-x:                       $spacer;

$custom-control-indicator-size:                 $spacer * 1.375;
$custom-control-indicator-bg:                   $btn-primary-bg;
$custom-control-indicator-bg-size:              18px 18px;
$custom-control-indicator-box-shadow:           none;

$custom-control-indicator-disabled-bg:          $disabled-color;
$custom-control-label-disabled-color:           $disabled-color;

$custom-control-indicator-checked-color:        $white;
$custom-control-indicator-checked-bg:           $btn-primary-bg;
$custom-control-indicator-checked-disabled-bg:  $disabled-color;
$custom-control-indicator-checked-box-shadow:   none;

$custom-control-indicator-focus-box-shadow:     $focus-box-shadow;

$custom-control-indicator-active-color:         $white;
$custom-control-indicator-active-bg:            darken($btn-primary-bg, $theme-color-interval) !important;
$custom-control-indicator-active-box-shadow:    none;

$custom-checkbox-indicator-border-radius:       $spacer * .25;
$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E"), "#", "%23");

$custom-checkbox-indicator-indeterminate-bg:    $btn-primary-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate:  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{$custom-checkbox-indicator-indeterminate-color}' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E"), "#", "%23");
$custom-checkbox-indicator-indeterminate-box-shadow: none;

// Custom radio
$custom-radio-indicator-border-radius:          50%;
$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E"), "#", "%23");

// Custom select
$custom-select-padding-y:           $input-padding-y;
$custom-select-padding-x:           $input-padding-x;
$custom-select-height:              $input-height;
$custom-select-indicator-padding:   $spacer; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:         $input-btn-line-height;
$custom-select-color:               $input-color;
$custom-select-disabled-color:      $disabled-color;
$custom-select-bg:                  $white;
$custom-select-disabled-bg:         $disabled-bg;
$custom-select-bg-size:             13px 13px; // In pixels because image dimensions
$custom-select-indicator-color:     $text-color;
$custom-select-indicator:           str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E"), "#", "%23");
$custom-select-indicator-white:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{$white}' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E"), "#", "%23");
$custom-select-indicator-disabled:  str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='#{$disabled-color}' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'/%3E%3C/svg%3E"), "#", "%23");
$custom-select-border-width:        $input-btn-border-width;
$custom-select-border-color:        $input-border-color;
$custom-select-border-radius:       0;

$custom-select-focus-border-color:  $input-focus-border-color;
$custom-select-focus-box-shadow:    $focus-box-shadow;

$custom-select-sm-padding-y:        .2rem;
$custom-select-font-size-sm:        75%;
$custom-select-height-sm:           $input-height-sm;

$custom-select-font-size-lg:        $font-size-lg;
$custom-select-height-lg:           $input-height-lg;

$custom-file-height:                $input-height;
$custom-file-focus-border-color:    $input-focus-border-color;
$custom-file-focus-box-shadow:      $input-btn-focus-box-shadow;

$custom-file-padding-y:             $input-btn-padding-y;
$custom-file-padding-x:             $input-btn-padding-x;
$custom-file-line-height:           $input-btn-line-height;
$custom-file-color:                 $input-color;
$custom-file-bg:                    $input-bg;
$custom-file-border-width:          $input-btn-border-width;
$custom-file-border-color:          $input-border-color;
$custom-file-border-radius:         0;
$custom-file-box-shadow:            $input-box-shadow;
$custom-file-button-color:          $custom-file-color;
$custom-file-button-bg:             $input-group-addon-bg;
$custom-file-text: (
  en: "Browse"
);


// Custom range
$custom-range-track-width:                   100%;
$custom-range-track-height:                  2px;
$custom-range-track-cursor:                  pointer;
$custom-range-track-bg:                      $btn-primary-bg;
$custom-range-track-border-radius:           0;
$custom-range-track-box-shadow:              none;

$custom-range-thumb-width:                   24px;
$custom-range-thumb-height:                  $custom-range-thumb-width;
$custom-range-thumb-bg:                      $btn-primary-bg;
$custom-range-thumb-border:                  0 solid $btn-primary-bg;
$custom-range-thumb-border-radius:           12px;
$custom-range-thumb-box-shadow:              none;
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $btn-primary-bg;
$custom-range-thumb-focus-box-shadow-width:  1px; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               $btn-primary-bg;
$custom-range-thumb-disabled-bg:             $disabled-color;


// Form validation
$form-feedback-margin-top:          $form-text-margin-top;
$form-feedback-font-size:           $font-size-sm;
$form-feedback-valid-color:         theme-color("success");
$form-feedback-invalid-color:       theme-color("danger");

// Dropdowns
$dropdown-min-width:                $spacer * 13; // 208px
$dropdown-max-width:                $spacer * 20; // 320px
$dropdown-wrap-min-width:           $spacer * 20; // 320px
$dropdown-max-height:               $spacer * 20; // 320px
$dropdown-scroll-max-height:        $spacer * 20; // 320px
$dropdown-padding-y:                1px;
$dropdown-spacer:                   $spacer;
$dropdown-bg:                       $white;
$dropdown-border-color:             $border-color;
$dropdown-border-radius:            0;
$dropdown-border-width:             $border-width;
$dropdown-divider-bg:               $border-color;
$dropdown-box-shadow:               $box-shadow;

$dropdown-link-color:               $component-color;
$dropdown-link-hover-color:         $dropdown-link-color;
$dropdown-link-hover-bg:            $component-bg-hover;

$dropdown-link-active-color:        $component-active-color;
$dropdown-link-active-bg:           $component-active-bg;
$dropdown-link-active-hover-bg:     $component-active-hover-bg;

$dropdown-link-disabled-color:      $disabled-color;

$dropdown-item-padding-y:           $spacer * .5;
$dropdown-item-padding-x:           $spacer;

$dropdown-header-color:             $headings-color;

// Z-index
$zindex-dropdown-backdrop:          990;
$zindex-dropdown:                   1000;
$zindex-navbar:                     1010;
$zindex-btn:                        1020;
$zindex-sticky:                     1030;
$zindex-popover:                    1040;
$zindex-fixed:                      1050;
$zindex-progressjs:                 $zindex-fixed + 1;
$zindex-fixed-dropdown:             $zindex-fixed + 5;
$zindex-tooltip:                    1070;
$zindex-modal-backdrop:             2000;
$zindex-modal:                      2010;
$zindex-dropdown-modal:             2020;
$zindex-lightbox-backdrop:          2030;
$zindex-lightbox:                   2040;

// Navs
$nav-link-padding-y:                .5rem;
$nav-link-padding-x:                $spacer;

$nav-link-color:                    $link-color;
$nav-link-hover-color:              $link-hover-color;
$nav-link-hover-bg:                 $component-bg-hover;
$nav-text-transform:                uppercase;

$nav-border-color:                  $border-color-light;

$nav-disabled-link-color:           $disabled-color;
$nav-disabled-link-hover-color:     $disabled-color;
$nav-disabled-link-hover-bg:        transparent;

$nav-tabs-border-color:             $border-color;
$nav-tabs-border-width:             $border-width;
$nav-tabs-border-radius:            0;
$nav-tabs-link-hover-border-color:  transparent transparent $border-color transparent;
$nav-tabs-link-active-color:        $component-color;
$nav-tabs-link-active-bg:           $white;
$nav-tabs-link-active-border-color: $border-color $border-color transparent $border-color;

$nav-pills-border-radius:             0;
$nav-pills-link-active-color:         $component-active-color;
$nav-pills-link-active-bg:            $component-active-bg;
$nav-pills-stacked-link-active-color: $component-color;
$nav-pills-stacked-link-active-bg:    transparent;

// Navbar
$navbar-bg:                         $dark-gray;
$navbar-fixed-bg:                   $navbar-bg;
$navbar-color:                      $white;
$navbar-height:                     $spacer * 3.5;
$navbar-padding-y:                  0;
$navbar-padding-x:                  0;
$navbar-nav-link-padding-x:         $spacer * .5;
$navbar-brand-font-size:            $font-size-base;
$nav-link-height:                   32px;
$navbar-brand-height:               $navbar-height;
$navbar-brand-padding-y:            0;

$navbar-toggler-padding-y:          .25rem;
$navbar-toggler-padding-x:          .75rem;
$navbar-toggler-font-size:          $font-size-lg;
$navbar-toggler-border-radius:      0;

$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        $disabled-color;
$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-dark-toggler-border-color:  transparent;

$navbar-light-color:                $white;
$navbar-light-hover-color:          $white;
$navbar-light-active-color:         $white;
$navbar-light-disabled-color:       $disabled-color;
$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$navbar-light-toggler-border-color: transparent;

// Pagination
$pagination-border-radius:          50%;
$pagination-spacing:                $spacer * .5;

$pagination-padding-y:              .55rem;
$pagination-padding-x:              .9rem;
$pagination-padding-y-sm:           $pagination-padding-y;
$pagination-padding-x-sm:           $pagination-padding-x;
$pagination-padding-y-lg:           $pagination-padding-y;
$pagination-padding-x-lg:           $pagination-padding-x;

$pagination-line-height:            1.25;

$pagination-color:                  $link-color;
$pagination-bg:                     transparent;
$pagination-border-width:           $border-width;
$pagination-border-color:           $border-color;

$pagination-focus-box-shadow:       none;

$pagination-hover-color:            $component-color;
$pagination-hover-bg:               transparent;
$pagination-hover-border-color:     transparent;

$pagination-active-color:           $pagination-hover-color;
$pagination-active-bg:              transparent;
$pagination-active-border-color:    $pagination-active-color;

$pagination-disabled-color:         $disabled-color;
$pagination-disabled-bg:            transparent;
$pagination-disabled-border-color:  transparent;

// Jumbotron
$jumbotron-padding:                 2rem;
$jumbotron-bg:                      $blue-dark;
$jumbotron-color:                   $white;

// Cards
$card-spacer-y:                     $spacer;
$card-spacer-x:                     $spacer;
$card-border-width:                 0;
$card-border-radius:                0;
$card-border-color:                 $border-color;
$card-inner-border-radius:          0;
$card-cap-bg:                       $disabled-bg;
$card-bg:                           $white;

$card-img-overlay-padding:          $spacer;

$card-group-margin:                 ($grid-gutter-width / 2);
$card-deck-margin:                  $card-group-margin;

$card-columns-count:                3;
$card-columns-gap:                  1.25rem;
$card-columns-margin:               $card-spacer-y;

// Tooltips
$tooltip-font-size:                 $font-size-sm;
$tooltip-max-width:                 20rem;
$tooltip-color:                     $white;
$tooltip-bg:                        $transparent-bg;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 ($spacer * .25);
$tooltip-padding-x:                 ($spacer * .5);
$tooltip-margin:                    1px;
$tooltip-arrow-width:               ($spacer * .5);
$tooltip-arrow-height:              ($spacer * .25);
$tooltip-arrow-color:               $tooltip-bg;

// Popovers
$popover-font-size:                 $font-size-sm;
$popover-bg:                        $white;
$popover-min-width:                 $spacer * 20;
$popover-max-width:                 $spacer * 20;
$popover-border-width:              0;
$popover-border-radius:             0;
$popover-border-color:              transparent;
$popover-box-shadow:                $box-shadow;

$popover-header-bg:                 $primary; 
$popover-header-color:              $white;
$popover-header-padding-y:          $spacer * .625;
$popover-header-padding-x:          $spacer;

$popover-body-color:                $text-color;
$popover-body-padding-y:            $spacer;
$popover-body-padding-x:            $spacer;
$popover-body-max-height:           $spacer * 20;

$popover-arrow-width:               $spacer;
$popover-arrow-height:              $spacer * .5;
$popover-arrow-color:               $white;

// Toasts
$toast-min-width:                   $spacer * 10;
$toast-max-width:                   $spacer * 20;
$toast-padding-x:                   $spacer * .5;
$toast-padding-y:                   $spacer * .25;
$toast-font-size:                   $font-size-sm;
$toast-color:                       $text-color;
$toast-background-color:            rgba($white, .85);
$toast-border-width:                0;
$toast-border-color:                transparent;
$toast-border-radius:               0;
$toast-box-shadow:                  $box-shadow-sm;

$toast-header-color:                $white;
$toast-text-muted-color:            rgba($white, .85);
$toast-header-background-color:     $primary;
$toast-header-border-color:         $border-color;

// Badges
$badge-font-size:                   $font-size-sm;
$badge-line-height:                 1.4em;
$badge-font-size-sm:                $font-size-sm;
$badge-line-height-sm:              $badge-line-height;
$badge-font-size-lg:                $font-size-base;
$badge-line-height-lg:              $line-height-base;
$badge-font-weight:                 normal;
$badge-color:                       $white;
$badge-height:                      $spacer * 1.5;
$badge-height-sm:                   $spacer * 1.25;
$badge-height-lg:                   $spacer * 2;
$badge-padding-y:                   .15em;
$badge-padding-x:                   .25em;
$badge-padding-y-sm:                0;
$badge-padding-x-sm:                .45em;
$badge-padding-y-lg:                .25em;
$badge-padding-x-lg:                .25em;
$badge-border-radius:               10rem;
$badge-pill-padding-x:              .6em;
$badge-pill-border-radius:          10rem;

$badge-default-bg:                  $dark-gray;
$badge-primary-bg:                  $primary;
$badge-success-bg:                  $success;
$badge-info-bg:                     $info;
$badge-warning-bg:                  $warning;
$badge-danger-bg:                   $danger;

// Modals
$modal-inner-padding:               0;
$modal-dialog-margin:               $spacer;
$modal-dialog-margin-y-sm-up:       $spacer * 6;

$modal-title-line-height:           $line-height-base;

$modal-content-bg:                  $white;
$modal-content-border-color:        $border-color;
$modal-content-border-width:        0;
$modal-content-box-shadow-xs:       $box-shadow;
$modal-content-box-shadow-sm-up:    $box-shadow-lg;

$modal-backdrop-bg:                 $transparent-black;
$modal-backdrop-opacity:            $transparent-black-alpha;

$modal-header-bg:                   $primary;
$modal-header-color:                $white;
$modal-header-border-color:         $border-color;
$modal-footer-border-color:         $modal-header-border-color;
$modal-header-border-width:         $modal-content-border-width;
$modal-footer-border-width:         $modal-header-border-width;
$modal-header-padding:              ($spacer * .5) $spacer;
$modal-header-min-height:           $spacer * 3;

$modal-lg:                          800px;
$modal-md:                          500px;
$modal-sm:                          300px;

$modal-transition:                  transform .3s ease-out;

// Alerts
$alert-padding-y:                   .75rem;
$alert-padding-x:                   1.25rem;
$alert-margin-bottom:               $spacer;
$alert-border-radius:               0;
$alert-link-font-weight:            $font-weight-bold;
$alert-border-width:                $border-width;

$alert-bg-level:                    -10;
$alert-border-level:                -9;
$alert-color-level:                 6;

// Progress bars
$progress-height:                   $spacer;
$progress-font-size:                ($font-size-base * .75);
$progress-bg:                       $disabled-bg;
$progress-border-radius:            0;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1);
$progress-bar-color:                $white;
$progress-bar-bg:                   theme-color("primary");
$progress-bar-animation-timing:     1s linear infinite;
$progress-bar-transition:           width .6s ease;

// List group
$list-group-bg:                     $white;
$list-group-border-color:           $border-color-light;
$list-group-border-width:           $border-width;
$list-group-border-radius:          0;

$list-group-item-padding-y:         .47rem;
$list-group-item-padding-x:         $spacer;

$list-group-hover-bg:               $component-bg-hover;
$list-group-active-color:           $component-active-color;
$list-group-active-bg:              $component-active-bg;
$list-group-active-border-color:    $list-group-active-bg;

$list-group-disabled-color:         $disabled-color;
$list-group-disabled-bg:            $list-group-bg;

$list-group-action-color:           $component-color;
$list-group-action-hover-color:     $list-group-action-color;

$list-group-action-active-color:    $component-active-color;
$list-group-action-active-bg:       $component-active-bg;

$list-group-link-color:             $component-color;
$list-group-link-hover-color:       $list-group-link-color;

// Image thumbnails
$thumbnail-padding:                 .25rem;
$thumbnail-bg:                      $body-bg;
$thumbnail-border-width:            $border-width;
$thumbnail-border-color:            $border-color;
$thumbnail-border-radius:           0;
$thumbnail-box-shadow:              0 1px 2px rgba($black,.075);
$thumbnail-transition:              all .2s ease-in-out;

// Figures
$figure-caption-font-size:          90%;
$figure-caption-color:              $gray-light;

// Breadcrumbs
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           .5rem;
$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $text-muted;
$breadcrumb-active-color:           $text-muted;
$breadcrumb-divider:                "/";
$breadcrumb-margin-bottom:          $spacer;

// Carousel
$carousel-control-color:            $white;
$carousel-control-width:            15%;
$carousel-control-opacity:          $focus-opacity;

$carousel-indicator-width:          30px;
$carousel-indicator-height:         3px;
$carousel-indicator-spacer:         3px;
$carousel-indicator-active-bg:      $white;

$carousel-caption-width:            70%;
$carousel-caption-color:            $white;

$carousel-control-icon-width:       20px;
$carousel-control-prev-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23");
$carousel-control-next-icon-bg:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23");

$carousel-transition:               transform .6s ease;

// Close
$close-font-size:                   $icon-font-size;
$close-font-weight:                 $font-weight-normal;
$close-color:                       $gray;
$close-text-shadow:                 none;
$close-hover-opacity:               $focus-opacity;

// Code
$code-font-size:                    14px;
$code-padding-y:                    .25rem;
$code-padding-x:                    .4rem;
$code-color:                        $pink-darker;
$code-bg:                           $light-gray;

$kbd-padding-y:                     .25rem;
$kbd-padding-x:                     .4rem;
$kbd-font-size:                     $code-font-size;
$kbd-color:                         $white;
$kbd-bg:                            $black-light;

$pre-color:                         $black-light;
$pre-scrollable-max-height:         340px;

// Printing
$print-page-size:                   a3;
$print-body-min-width:              992px;