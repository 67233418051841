// Tint - Lighten a color
@function tint($color, $percentage)
{
  @return mix(#fff, $color, $percentage);
}

// Shade - Darken a color
@function shade($color, $percentage)
{
  @return mix(#000, $color, $percentage);
}

// Focus outline on keydown tab
@mixin focus-outline()
{
  transition: $focus-transition;
  &:focus:not(:hover):not(:active):not(.active)
  {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }
  &:hover,
  &:active,
  &.active
  {
    outline: none;
  }
}

// Focus box-shadow
@mixin focus-box-shadow()
{
  transition: $focus-transition;
  &:focus
  {
    box-shadow: $focus-box-shadow;
  }
  &:active,
  &.active
  {
    box-shadow: none;
  }
  &:focus,
  &:hover,
  &:active,
  &.active
  {
    outline: none;
  }
}

// Calc
@mixin calc($key, $value)
{
  #{$key}: -webkit-calc(#{$value});
  #{$key}: -moz-calc(#{$value});
  #{$key}: calc(#{$value});
}

// Hide
@mixin hide()
{
  display: none !important;
  visibility: hidden !important;
}

// Visibility
@mixin visuallyhidden()
{
  visibility: hidden;
  overflow: hidden;
  clip: rect(0 0 0 0); 
  height: 0;
  width: 0;
  padding: 0;
  border: 0;
  font-size: 0;
  color: transparent;
}

@mixin visuallyvisible()
{
  visibility: visible;
  overflow: show;
  clip: none; 
  height: auto;
  width: auto;
  font-size: inherit;
  color: inherit;
}

// The old show/hide class
.show
{
  display: block;
}
.hide
{
  display: none !important;
}

// Hide overflow text
.hideextra
{ 
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


// Pointer events
.pointer-click-events-none
{
  -webkit-touch-callout: none;
  pointer-events: none;
  user-select: none;
}

.pointer-click-events-all
{
  -webkit-touch-callout: default;
  pointer-events: all;
  user-select: all;
}

.pointer-events-none
{
  -webkit-touch-callout: none;
  user-select: none;
  pointer-events: none;
  cursor: default;
}

.pointer-events-all
{
  -webkit-touch-callout: default;
  user-select: all;
  pointer-events: all;
  cursor: pointer;
}

// User select
.user-select-none
{
  user-select: none;
}

.user-select-all
{
  user-select: all;
}

// Inner scroll
.inner
{
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

// Inner scroll-y
.inner-y
{
  @extend .inner;
  overflow-x: hidden;
}

// Inner scroll-x
.inner-x
{
  @extend .inner;
  overflow-y: hidden;
}