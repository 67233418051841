// Sweco logotype

$logotype-white: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 58.2'%3E%3Cpath fill='#{$white}' d='M189.6,46.8v5.1h-30.9v-5.1l15.4-15.3L189.6,46.8z M200,24.4l-18.8,0l12.1-12.1L189,7.9l-14.9,14.9L159,7.7 l-4.3,4.4l12.3,12.3h-18.7V30H167l-14.4,14.2v13.7h43V44.2L181.3,30H200V24.4z'/%3E%3E%3Cpath fill='#{$white}' d='M180.3,6.1c0,3.4-2.7,6.1-6.1,6.1c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1C177.5,0,180.3,2.7,180.3,6.1'/%3E%3E%3Cpath fill='#{$white}' d='M123.7,32.9c8.1,0,12.4,4.6,12.4,12.6c0,7.8-4.3,12.6-12.4,12.6c-8.1,0-12.4-4.8-12.4-12.6 C111.3,37.5,115.6,32.9,123.7,32.9 M123.7,53.3c4,0,5.4-3.8,5.4-7.8c0-4.3-1.3-7.8-5.4-7.8c-4,0-5.6,3.5-5.6,7.8 C118.1,49.6,119.7,53.3,123.7,53.3'/%3E%3E%3Cpath fill='#{$white}' d='M99.5,32.9c2.4,0,4.8,0.5,7.3,1.1l-0.5,5.4c-2.2-1.1-4.3-1.6-6.5-1.6c-4.6,0-7.8,3.2-7.8,7.8 c0,4.8,3.5,7.8,8.3,7.8c2.2,0,4.3-0.5,6.2-1.3l0.3,5.1c-1.9,0.5-4,1.1-7.3,1.1c-6.7,0-14.3-3.2-14.3-12.6 C85.2,36.9,91.4,32.9,99.5,32.9'/%3E%3E%3Cpolygon fill='#{$white}' points='62.7,33.2 79.9,33.2 79.9,37.7 68.8,37.7 68.8,42.8 79.1,42.8 79.1,47.7 68.8,47.7 68.8,53.1 80.1,53.1 80.1,57.9 62.7,57.9'/%3E%3E%3Cpolygon fill='#{$white}' points='22.9,33.2 29.6,33.2 32.5,52 32.8,52 36,33.2 44.4,33.2 47.9,52 51.1,33.2 57.5,33.2 51.9,57.9 43.6,57.9 40.1,38.8 36.8,57.9 28.2,57.9'/%3E%3E%3Cpath fill='#{$white}' d='M10.8,32.9c2.4,0,4.6,0.3,6.5,0.8l-0.5,5.1c-1.6-0.8-3.5-1.1-5.4-1.1c-1.6,0-4.6,0-4.6,2.2 c0,4,11.8,1.3,11.8,10.2c0,6.5-5.4,8.1-11,8.1c-2.1,0-5.1-0.3-7.3-1.1l0.5-5.4c1.9,0.8,4,1.6,6.7,1.6c2.2,0,4-0.5,4-2.4 C11.6,46.3,0,49.3,0,40.4C0,34.5,5.9,32.9,10.8,32.9'/%3E%3C/svg%3E"), "#", "%23");

$logotype-black: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 58.2'%3E%3Cpath fill='#{$black}' d='M189.6,46.8v5.1h-30.9v-5.1l15.4-15.3L189.6,46.8z M200,24.4l-18.8,0l12.1-12.1L189,7.9l-14.9,14.9L159,7.7 l-4.3,4.4l12.3,12.3h-18.7V30H167l-14.4,14.2v13.7h43V44.2L181.3,30H200V24.4z'/%3E%3E%3Cpath fill='#{$black}' d='M180.3,6.1c0,3.4-2.7,6.1-6.1,6.1c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1C177.5,0,180.3,2.7,180.3,6.1'/%3E%3E%3Cpath fill='#{$black}' d='M123.7,32.9c8.1,0,12.4,4.6,12.4,12.6c0,7.8-4.3,12.6-12.4,12.6c-8.1,0-12.4-4.8-12.4-12.6 C111.3,37.5,115.6,32.9,123.7,32.9 M123.7,53.3c4,0,5.4-3.8,5.4-7.8c0-4.3-1.3-7.8-5.4-7.8c-4,0-5.6,3.5-5.6,7.8 C118.1,49.6,119.7,53.3,123.7,53.3'/%3E%3E%3Cpath fill='#{$black}' d='M99.5,32.9c2.4,0,4.8,0.5,7.3,1.1l-0.5,5.4c-2.2-1.1-4.3-1.6-6.5-1.6c-4.6,0-7.8,3.2-7.8,7.8 c0,4.8,3.5,7.8,8.3,7.8c2.2,0,4.3-0.5,6.2-1.3l0.3,5.1c-1.9,0.5-4,1.1-7.3,1.1c-6.7,0-14.3-3.2-14.3-12.6 C85.2,36.9,91.4,32.9,99.5,32.9'/%3E%3E%3Cpolygon fill='#{$black}' points='62.7,33.2 79.9,33.2 79.9,37.7 68.8,37.7 68.8,42.8 79.1,42.8 79.1,47.7 68.8,47.7 68.8,53.1 80.1,53.1 80.1,57.9 62.7,57.9'/%3E%3E%3Cpolygon fill='#{$black}' points='22.9,33.2 29.6,33.2 32.5,52 32.8,52 36,33.2 44.4,33.2 47.9,52 51.1,33.2 57.5,33.2 51.9,57.9 43.6,57.9 40.1,38.8 36.8,57.9 28.2,57.9'/%3E%3E%3Cpath fill='#{$black}' d='M10.8,32.9c2.4,0,4.6,0.3,6.5,0.8l-0.5,5.1c-1.6-0.8-3.5-1.1-5.4-1.1c-1.6,0-4.6,0-4.6,2.2 c0,4,11.8,1.3,11.8,10.2c0,6.5-5.4,8.1-11,8.1c-2.1,0-5.1-0.3-7.3-1.1l0.5-5.4c1.9,0.8,4,1.6,6.7,1.6c2.2,0,4-0.5,4-2.4 C11.6,46.3,0,49.3,0,40.4C0,34.5,5.9,32.9,10.8,32.9'/%3E%3C/svg%3E"), "#", "%23");

.logotype-white
{
  background-image: $logotype-white;
}

.logotype-black
{
  background-image: $logotype-black;
}

// Logotype
.logotype-white,
.logotype-black
{
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  border: 0;
  display: block;
  @include hover-focus-active
  {
    text-decoration: none;
    outline: 0 !important;
  }
  background-repeat: no-repeat;
  width: 128px;
  height: 56px;
  background-position: $spacer;
  background-size: 96px 32px;
  &.logotype-md
  {
    background-position: $spacer * 1.5;
    width: 192px;
    height: 96px;
    background-size: 144px 48px;
  }
  &.logotype-lg
  {
    background-position: $spacer * 2;
    width: 256px;
    height: 128px;
    background-size: 192px 64px;
  }
}
