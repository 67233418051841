#sun path,
#sun polygon
{
  fill: none;
}

#sun-numbers polygon,
#sun-numbers path
{
  fill: #fff;
}
#sun-logo path,
#neg path.active
{
  fill: #707070;
}
#sun-logo-17.active,
#sun_17 .active
{
  fill: #004c6d;
  stroke: #004c6d; 
  stroke-width: 1.5;
}
#sun-logo-16.active,
#sun_16 path.active
{
  fill: #0070a1;
  stroke: #0070a1; 
  stroke-width: 1.5;
}
#sun-logo-15.active,
#sun_15 .active
{
  fill: #39b54a;
  stroke: #39b54a; 
  stroke-width: 1.5;
}
#sun-logo-14.active,
#sun_14 .active
{
  fill: #009fe0;
  stroke: #009fe0; 
  stroke-width: 1.5;
}
#sun-logo-13.active,
#sun_13 .active
{
  fill: #008548;
  stroke: #008548; 
  stroke-width: 1.5;
}
#sun-logo-12.active,
#sun_12 .active
{
  fill: #cb892e;
  stroke: #cb892e; 
  stroke-width: 1.5;
}
#sun-logo-11.active,
#sun_11 .active
{
  fill: #f79837;
  stroke: #f79837; 
  stroke-width: 1.5;
}
#sun-logo-10.active,
#sun_10 .active
{
  fill: #e02f87;
  stroke: #e02f87; 
  stroke-width: 1.5;
}
#sun-logo-9.active,
#sun_9 .active
{
  fill: #f26e3f;
  stroke: #f26e3f; 
  stroke-width: 1.5;
}
#sun-logo-8.active,
#sun_8 .active
{
  fill: #a21942;
  stroke: #a21942; 
  stroke-width: 1.5;
}
#sun-logo-7.active,
#sun_7 .active
{
  fill: #FCC30B;
  stroke: #FCC30B; 
  stroke-width: 1.5;
}
#sun-logo-6.active,
#sun_6 .active
{
  fill: #26bde2;
  stroke: #26bde2; 
  stroke-width: 1.5;
}
#sun-logo-5.active,
#sun_5 .active
{
  fill: #ff3a21;
  stroke: #ff3a21; 
  stroke-width: 1.5;
}
#sun-logo-4.active,
#sun_4 .active
{
  fill: #c5192d;
  stroke: #c5192d; 
  stroke-width: 1.5;
}
#sun-logo-3.active,
#sun_3 .active
{
  fill: #4c9f38;
  stroke: #4c9f38; 
  stroke-width: 1.5;
}
#sun-logo-2.active,
#sun_2 .active
{
  fill: #dda63a;
  stroke: #dda63a; 
  stroke-width: 1.5;
}
#sun-logo-1.active,
#sun_1 .active
{
  fill: #e5243b;
  stroke: #e5243b; 
  stroke-width: 1.5;
}