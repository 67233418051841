// Sweco

body
{
  // If .navbar-north add .navbarNorth class to body
  &.navbarNorth
  {
    padding-top: $navbar-height;
  }
  // If .navbar-south add .navbarSouth class to body
  &.navbarSouth
  {
    padding-bottom: $navbar-height;
  }
  // Fixed body for non scroll content
  &.body-fixed
  {
    overflow: hidden;
  }
  // Container
  > .container,
  > .container-fluid,
  // #root for Reactjs
  > #root > .container,
  > #root > .container-fluid
  {
    position: relative;
    padding-right: $spacer;
    padding-left:  $spacer;
    @include media-breakpoint-up(md)
    {
      padding-left: $grid-gutter-width !important;
      padding-right: $grid-gutter-width !important;
    }
    // spacing between container rows
    .container-padding
    {
      margin-top: $grid-gutter-width;
      @include media-breakpoint-up(sm)
      {
        background-color: $body-bg;
        min-height: $grid-gutter-width;
        margin-left: - $grid-gutter-width;
        margin-right: - $grid-gutter-width;
        margin-bottom: $spacer * 1.5;
      }
    }
  }
}

// Windows fix for scroll jump on fixed-navbars on scroll
body.win
{
  @include media-breakpoint-up(sm)
  {
    .navbar.fixed-top,
    .navbar.fixed-bottom
    {
      padding-left: calc(100vw - 100%) !important;
      > .container-fluid > .row,
      > .container > .row
      {
        margin-left: -$spacer !important;
      }
    }
  }
  @include media-breakpoint-down(sm)
  {
    .nav-toolbar
    {
      right: $spacer * 5 !important;
    }
    .navbar-toggler
    {
      left: calc(100% - 64px) !important;
    }
  }
  @include media-breakpoint-down(md)
  {
    .nav-toolbar
    {
      right: $spacer * 6;
    }
    .navbar-toggler
    {
      left: calc(100% - 80px);
    }
  }
}

// Remove IE clear and reveal on inputs
::-ms-clear
{
  display: none;
}

::-ms-reveal
{
  display: none;
}

// Text selection
::selection
{
  background: $gray-lighter;
}
::-moz-selection
{
  background: $gray-lighter;
}

// Links
a
{
  @include focus-outline;
}

// Typography
h1, h2, h3, h4, h5
.h1, .h2, .h3, .h4, .h5
legend, label,
.btn, .nav, .pagination, .badge, .breadcrumb, .list-group
{
  font-family: $headings-font-family;
  font-weight: normal !important;
}

// Typography heavy
h6, .h6,
strong, b, dt, th, .btn-xs
{
  font-family: $font-family-heavy;
  font-weight: normal !important;
}

// Typography font-smoothing
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
legend, label, strong, b, dt, th,
.btn, .nav, .pagination, .badge , .breadcrumb, .list-group, .active .step-header
{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Typography text-transform uppercase
h6, .h6, th,
.btn, .nav, .pagination, .badge, .breadcrumb, .list-group, .accordion .card-header, .modal-title, .popover-header
{
  text-transform: uppercase;
}

// Headings color
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
legend, label, dt, th
{
  color: $headings-color;
}

h1,
.h1
{
  font-size: $h1-font-size;
  line-height: $h1-line-height;
}

h2,
.h2
{
  font-size: $h2-font-size;
  line-height: $h2-line-height;
}

h3,
.h3
{
  font-size: $h3-font-size;
  line-height: $h3-line-height;
}

h4,
.h4
{
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5,
.h5
{
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

h6,
.h6
{
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}

// Typography size class
.font-size-xs
{
  font-size: $font-size-xs;
  line-height: $line-height-xs;
}
.font-size-sm
{
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}
.font-size-base
{
  font-size: $font-size-base;
}
.font-size-lg
{
  font-size: $font-size-lg;
  line-height: $line-height-lg;
}
.font-size-xl
{
  font-size: $font-size-xl;
  line-height: $line-height-xl;
}

.display-1,
.display-2,
.display-3,
.display-4
{
  font-family: $font-family-regular;
  text-transform: none;
}

.display-1
{
  line-height: $line-height-display-1;
}

.display-2
{
  line-height: $line-height-display-2;
}

.display-3
{
  line-height: $line-height-display-3;
}

.display-4
{
  line-height: $line-height-display-4;
}

.page-header
{
  padding: $spacer 0;
}

.lead
{
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  @include media-breakpoint-up(lg)
  {
    font-size: $lead-font-size;
    line-height: $lead-line-height;
  }
}

blockquote
{
  font-family: $font-family-base;
  line-height: $line-height-lg;
  color: $blockquote-color;
  border: 0 !important;
  .blockquote-footer
  {
    font-family: $font-family-base !important;
    font-size: $font-size-base !important;
    line-height: $line-height-base !important;
    margin-left: 0;
    margin-right: 0;
    &:before
    {
      content: "\002D \00A0" !important;
    }
    &:after
    {
      content: "\00A0 \002D" !important;
    }
  }
}

address,
cite
{
  font-style: normal;
}

cite
{
  &:before
  {
    content: "\002D \00A0";
  }
}

.text-muted
{
  font-size: inherit;
}

// Label
label,
.label
{
  @extend .user-select-none;
}

// Code
code
{
  background-color: $code-bg;
  box-shadow: $box-shadow-inset;
  margin: $spacer 0;
  padding: $code-padding-y $code-padding-x;
}

pre
{
  background-color: $code-bg;
  box-shadow: $box-shadow-inset;
  margin: $spacer 0;
  padding: $spacer;
  overflow-y: hidden;
  word-break: keep-all;
  white-space: pre;
  code
  {
    display: block;
    box-shadow: none;
    color: $code-color;
    margin: 0;
    padding: 0;
    word-break: keep-all;
    white-space: pre;
  }
}

samp
{
  font-size: $font-size-sm;
}

// White text
.white-text,
.text-color-white
{
  color: $white;
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6,
  legend,
  label,
  p, li, dt, dd, th, td,
  .lead,
  blockquote
  {
    color: inherit;
    a
    {
      text-decoration: underline;
      color: $white;
      @include hover-focus
      {
        color: $white;
      }
    }
  }
}

// Table
table
{
  th,
  td
  {
    padding: 0;
    vertical-align: top;
  }
  thead th
  {
    vertical-align: bottom;
    border-bottom: 0;
  }
  tbody + tbody
  {
    border-top: 0;
  }
  &.table
  {
    background-color: $table-bg;
    width: 100%;
    max-width: 100%;
    border: 0;
    border-bottom: $border-width solid $border-color;
    &.table-bordered,
    &.table-borderless
    {
      border: 0;
    }
    thead
    {
      th
      {
        border-bottom-width: 1px;
      }
    }
    tbody + tbody
    {
      border-top-width: 1px;
    }
    tbody
    {
      th
      {
        width: 1%;
        text-align: right;
      }
    }
    tr
    {
      transition: $transition;
    }
  }
  &.table-sm
  {
    th,
    td
    {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
    }
  }
}

// Custom select
.custom-select-indicator
{
  &:after
  {
    position: absolute;
    top: $spacer * .675;
    right: $spacer * .5;
    content: "";
    display: block;
    border: 0 !important;
    width: 16px !important;
    height: 16px !important;
    background-image: $custom-select-indicator;
    background-repeat: no-repeat;
    background-position: 1px 2px;
    background-size: $custom-select-bg-size;
    transition: $transition;
    transform: rotate(-90deg);
  }
  &.btn:not(.btn-secondary)
  {
    &:after
    {
      top: $spacer * .7;
    }
  }
  &[aria-expanded="true"]
  {
    &:after
    {
      transform: rotate(0deg);
    }
  }
  &.disabled,
  &[disabled]
  {
    &:after
    {
      background-image: $custom-select-indicator-disabled !important;
    }
  }
}
.custom-select-indicator-white
{
  &:after
  {
    background-image: $custom-select-indicator-white;
  }
}
.custom-select-white
{
  background-image: $custom-select-indicator-white;
}
.custom-select-disabled
{
  background-image: $custom-select-indicator-disabled !important;
}
.btn-left,
.btn-right,
.custom-select,
select.form-control:not([size]):not([multiple])
{
  &.disabled,
  &[disabled]
  {
    @extend .custom-select-disabled;
  }
}

// Btn styles
.btn
{
  // Icon spacing
  > .fa,
  > .far,
  > .fas,
  > .fab,
  > .fal,
  > i
  {
    margin-right: $spacer * .5;
  }
  // Icon right spacing
  &.btn-icon-right
  {
    > .fa,
    > .far,
    > .fas,
    > .fab,
    > .fal,
    > i
    {
      margin-left: 0;
      margin-right: $spacer * .5;
    }
  }
  // Btn only icon
  &.btn-icon
  {
    width: $spacer * 2;
    height: $spacer * 2;
    padding: 0 !important;
    > .fa,
    > .far,
    > .fas,
    > .fab,
    > .fal,
    > i
    {
      font-size: $icon-font-size;
      margin: 0;
    }
    transition: $transition;
    opacity: 1;
    @include hover-focus-active
    {
      opacity: $focus-opacity;
    }
  }
  // Btn icon
  &.btn-user,
  &.btn-faq,
  &.btn-lang
  {
    @extend .btn-icon;
    border-radius: 50%;
    overflow: hidden;
    background-position: 50% 50%;
    @include hover-focus-active
    {
      opacity: $focus-opacity;
    }
  }
  // Btn faq
  &.btn-faq
  {
    @extend .icon-faq-light-white;
  }
  // Btn lang
  &.btn-lang
  {
    @extend .icon-lang-light-white;
  }
  // Btn user
  &.btn-user
  {
    @extend .icon-user-light-white;
    img
    {
      width: $spacer * 2;
      height: $spacer * 2;
    }
  }
}

.icon-right,
.fa-right
{
  margin-right: 0 !important;
  margin-left: $spacer * .5 !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6
{
  > .fa
  {
    margin-right: $spacer * .5;
  }
}

// Navbar
.navbar
{
  background: $navbar-bg;
  min-height: $navbar-height;
  @include media-breakpoint-up(lg)
  {
    max-height: $navbar-height;
  }
  &.navbar-north,
  &.navbar-south
  {
    background: $navbar-fixed-bg;
  }
  &.navbar-south
  {
    transition: $transition;
    // When show fading in, animate it to slide up
    &.fade
    {
      transform: translate3d(0, 100%, 0) !important;
    }
    &.show
    {
      transform: translate3d(0, 0, 0) !important;
    }
  }
  &.navbar-dark,
  &.navbar-light
  {
    background: $navbar-bg;
  }
  .container,
  .container-fluid
  {
    position: relative;
    @include media-breakpoint-up(lg)
    {
      > .row
      {
        flex-basis: 100%;
        position: relative;
      }
    }
    @include media-breakpoint-down(md)
    {
      > .row
      {
        display: block;
        width: 100%;
        > .col
        {
          display: block;
          width: 100%;
        }
      }
    }
    @include media-breakpoint-down(lg)
    {
      padding-right: $spacer;
      padding-left: $spacer;
    }
    @include media-breakpoint-down(sm)
    {
      padding-right: 0;
      padding-left: 0;
    }
  }
  // Navbar collapse
  .navbar-collapse
  {
    align-items: stretch;
    @include media-breakpoint-up(lg)
    {
      display: flex;
    }
    @include media-breakpoint-down(md)
    {
      padding-bottom: $spacer;
    }
  }
  // Dropdown
  .dropdown-menu
  {
    @include media-breakpoint-up(lg)
    {
      margin-top: $spacer * .5;
    }
  }
  .navbar-search
  {
    position: relative;
    @include media-breakpoint-up(lg)
    {
      order: 2;
      margin: ($spacer * .5) auto 0;
    }
    @include media-breakpoint-down(md)
    {
      margin: $spacer $spacer 0;
      order: 3;
    }
    .input-group
    {
      flex-wrap: nowrap;
      > div
      {
        width: 100%;  
      }
      .input-group-prepend,
      .input-group-append
      {
        position: relative;
        width: auto !important;
        margin: 0 !important;
        @include media-breakpoint-down(md)
        {
          .dropdown-menu
          {
            left: $spacer * .125 !important;
            right: auto !important;
            &:before,
            &:after
            {
              left: $spacer * .4 !important;
              right: auto !important;
            }
          }
        }
      }
      .form-control
      {
        outline: 0 none !important;
        border: 0 !important;
        min-width: $spacer * 14;
        @include media-breakpoint-up(lg)
        {
          min-width: $spacer * 16;
        }
        @include media-breakpoint-up(xl)
        {
          min-width: $spacer * 20;
        }
        padding-right: $spacer * 2 !important;
        &:focus,
        &:active
        {
          border-color: $primary;
          box-shadow: $focus-box-shadow-noborder;
        }
      }
    }
  }
  // Navbar nav
  .navbar-nav
  {
    @include media-breakpoint-up(lg)
    {
      min-width: 0; // For FFOX
      flex: none; // For IE 11
      order: 3;
      margin-top: $spacer * .55;
    }
    @include media-breakpoint-down(md)
    {
      display: block;
      order: 2;
    }
  }
  // Navbar navigation
  .nav
  {
    margin: 0 $spacer;
    @include media-breakpoint-up(lg)
    {
      flex-wrap: nowrap;
    }
    @include media-breakpoint-down(md)
    {
      display: block;
    }
    .nav-item + .nav-item
    {
      margin-left: $spacer;
    }
    .nav-item
    {
      @include media-breakpoint-up(lg)
      {
        max-height: $nav-link-height;
      }
      @include media-breakpoint-down(md)
      {
        margin-left: 0 !important;
      }
      .nav-link
      {
        position: relative;
        border: 0;
        background-color: transparent;
        color: $white;
        white-space: nowrap;
        padding: 7px 0 2px !important;
        height: $nav-link-height;
        min-height: $nav-link-height !important;
        @include hover-focus-active
        {
          color: $white;
          text-decoration: none;
          background-color: transparent;
        }
        &.dropdown-toggle
        {
          padding-right: $spacer * 1.5 !important;
          &:after
          {
            top: 10px;
            right: 0;
          }
        }
        &:before,
        &:focus:before
        {
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 100%;
          height: 1px;
          background: $white;
          content: "";
          opacity: 0;
          transition: height .2s, opacity .2s, transform .2s;
          transform: translateY(-5px);
        }
        &:hover:before,
        &:focus:before,
        &.active:before
        {
          height: 2px;
          opacity: 1;
          transform: translateY(0)
        }
        &.disabled
        {
          &:hover:before,
          &:focus:before
          {
            display: none;
          }
        }
        @include media-breakpoint-down(md)
        {
          display: inline-block;
          width: auto;
        }
      }
      // Dropdown
      .dropdown-menu
      {
        z-index: $zindex-fixed-dropdown;
        left: auto;
        right: 0;
        margin-top: $spacer;
        &:after
        {
          left: auto;
          right: $spacer * .4;
        }
        &:before
        {
          left: auto;
          right: $spacer * .4;
        }
        @include media-breakpoint-down(md)
        {
          background-color: transparent;
          box-shadow: none;
          margin-top: $spacer * .5;
          margin-bottom: 0;
          padding-left: $spacer;
          .dropdown-item
          {
            position: relative;
            color: $navbar-color;
            padding-left: 0;
            padding-right: 0;
            &:hover,
            &:focus
            {
              background-color: transparent;
            }
            &:before,
            &:focus:before
            {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: $white;
              content: "";
              opacity: 0;
              transition: height .2s, opacity .2s, transform .2s;
              transform: translateY(-5px);
            }
            &:hover:before,
            &:focus:before
            {
              height: 2px;
              opacity: 1;
              transform: translateY(0)
            }
          }
        }
      }
      @include media-breakpoint-down(md)
      {
        &.dropdown.show
        {
          .dropdown-menu
          {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .dropdown-item
            {
              position: relative;
              display: inline-block;
              width: auto !important;
              min-width: auto !important;
            }
            &:before,
            &:after
            {
              display: none;
              border: 0;
            }
          }
        }
      }
    }
  }
  // Btn
  .btn
  {
    color: $white;
    @include hover-focus-active
    {
      color: $white;
    }
  }
  // Nav toolbar
  .nav-toolbar
  {
    display: flex;
    position: absolute;
    top: $spacer * .75;
    right: $spacer;
    z-index: $zindex-navbar;
    @include media-breakpoint-down(md)
    {
      position: absolute;
      right: $spacer * 5;
    }
    > div
    {
      position: relative;
      + div
      {
        margin-left: $spacer;
      }
      .btn
      {
        width: $spacer * 2;
        height: $spacer * 2;
        // Icon size
        > .fa,
        > .far,
        > .fas,
        > .fab,
        > .fal,
        i
        {
          font-size: $font-size-lg;
          margin-top: $spacer * .25;
        }
        &.dropdown-toggle
        {
          &:after
          {
            display: none;
          }
        }
      }
    }
    .dropdown-menu-right
    {
      margin-top: $spacer * .75 !important;
      right: 0;
      @include media-breakpoint-down(xs)
      {
        position: fixed;
        top: $navbar-height;
        right: $spacer;
        left: $spacer;
        margin-top: $spacer !important;
        &:before,
        &:after
        {
          display: none;
        }
      }
    }
  }
  &.fixed-top,
  &.fixed-bottom
  {
    box-shadow: $box-shadow;
    .nav-toolbar
    {
      z-index: $zindex-fixed-dropdown;
    }
  }
  &.fixed-bottom
  {
    > .container > .row
    {
      min-height: $spacer * 3.5;
    }
    .dropdown.dropup
    {
      .dropdown-menu
      {
        margin-bottom: $spacer * .5 !important;
        margin-right: -($spacer * .5) !important;
      }
    }
  }
}

// Front page
.body-front-page
{
  @extend .body-fixed;
  #content
  {
    display: none;
  }
}

.front-page
{
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-sticky;
  width: 100%;
  min-width: 100%;
  height: 100vh;
  display: table;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-attachment: fixed;
  background-size: cover;
  background-color: $body-bg;
  .hero
  {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    @extend .white-text;
    h1
    {
      font-size: $h4-font-size;
      line-height: $h4-line-height;
      @include media-breakpoint-up(lg)
      {
        font-size: $h2-font-size;
        line-height: $h2-line-height;
      }
    }
    .jumbo
    {
      background-color: $transparent-bg;
      @extend .white-text;
      padding: $spacer;
      @include media-breakpoint-up(lg)
      {
        padding: $spacer * 2;
      }
    }
  }
}

// Version in headers
.version
{
  font-size: $font-size-sm;
  text-transform: none;
  margin-left: $spacer;
  white-space: nowrap;
}

// Header
header.header-image
{
  height: 40vw;
  min-height: $spacer * 18;
  max-height: $spacer * 30;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  @include media-breakpoint-up(sm)
  {
    margin-bottom: $spacer-y;
  }
  .navbarNorth &
  {
    margin-top: -$navbar-height;
    .content-b
    {
      padding-top: $navbar-height;
    }
  }
  > .content-a
  {
    display: table;
    width: 100%;
    height: inherit;
    min-height: inherit;
    max-height: inherit;
    > .content-b
    {
      display: table-cell;
      position: relative;
      vertical-align: middle;
      text-align: left;
      @extend .white-text;
      h1
      {
        text-shadow: $text-shadow;
        margin-left: $spacer;
        margin-right: $spacer;
        @include media-breakpoint-down(sm)
        {
          font-size: $h4-font-size;
          line-height: $h4-line-height;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

// Footer
.footer
{
  background-color: $dark-gray;
  margin-top: $spacer-y;
  padding: $spacer 0;
  min-height: $spacer * 4.5;
  @extend .white-text;
  .container
  {
    padding: 0 $spacer;
    @include media-breakpoint-up(md)
    {
      padding: 0;
    }
    .logotype
    {
      margin-right: 0;
      @include media-breakpoint-up(sm)
      {
        margin-right: -$spacer;
      }
    }
  }
  .container-fluid
  {
    padding: 0 $spacer;
    @include media-breakpoint-up(md)
    {
      padding: 0 ($spacer * 2);
    }
    .logotype
    {
      margin-right: 0;
      @include media-breakpoint-up(sm)
      {
        margin-right: -$spacer;
      }
    }
  }
  p, li, dt, dd, th, td, address
  {
    font-size: $font-size-sm;
  }
  p, ul, ol, table, address
  {
    margin-bottom: $spacer * .5;
  }
  h1, h2, h3, h4, h5, h6
  {
    color: $text-muted;
    text-transform: none;
    margin-bottom: $spacer * .25;
  }
  a
  {
    text-decoration: none;
    @include hover-focus-active
    {
      opacity: .8;
      text-decoration: underline;
    }
  }
  .social
  {
    font-size: $font-size-xl;
    display: flex;
    flex-wrap: wrap;
    a
    {
      color: $white !important;
      text-decoration: none !important;
      + a
      {
        margin-left: $spacer * .75;
      }
    }
  }
  .logotype
  {
    margin-right: -$spacer;
  }
}

// Button
.btn
{
  position: relative;
  box-sizing: border-box;
  transition: $transition-color;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  text-transform: $btn-text-transform;
  text-decoration: none;
  outline: 0 !important;
  border-style: none;
  @include hover-focus
  {
    outline: 0 !important;
    cursor: pointer;
  }
  &.btn-xs
  {
    font-size: $btn-font-size-xs;
    line-height: $btn-line-height-xs;
    height: $input-height-xs;
    padding: $btn-padding-x-xs $btn-padding-y-xs;
  }
  &.btn-sm
  {
    font-size: $btn-font-size-sm;
    line-height: $btn-line-height-sm;
  }
  &.btn-lg
  {
    font-size: $btn-font-size-lg;
    line-height: $btn-line-height-lg;
  }
  &.btn-primary,
  &.btn-info
  {
    background-color: $btn-primary-bg;
    border-color: $btn-primary-bg;
    color: $btn-primary-color;
    @include hover-focus
    {
      color: $btn-primary-color;
      background-color: darken($btn-primary-bg, $theme-color-interval);
      border-color: darken($btn-primary-bg, $theme-color-interval);
    }
  }
  &.btn-dark
  {
    @include hover-focus
    {
      background-color: darken($btn-dark-bg, $theme-color-interval);
      border-color: darken($btn-dark-bg, $theme-color-interval);
    }
  }
  &.btn-secondary
  {
    color: $btn-secondary-color;
    border: 1px solid $btn-secondary-border;

    padding-top: $input-btn-padding-y;
    padding-bottom: $input-btn-padding-y;
    &.btn-xs
    {
      padding-top: $input-btn-padding-y-xs;
      padding-bottom: $input-btn-padding-y-xs;
    }
    &.btn-sm
    {
      padding-top: $input-btn-padding-y-sm + .03rem;
      padding-bottom: $input-btn-padding-y-sm + .03rem;
    }
    &.btn-lg
    {
      padding-top: $input-btn-padding-y-lg;
      padding-bottom: $input-btn-padding-y-lg;
    }
    &:hover,
    &:focus,
    &:active,
    &.focus,
    &.active,
    .show > &.dropdown-toggle
    {
      background-color: $component-bg-hover !important;
      border-color: $btn-secondary-border !important;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] &
    {
      border: 0;
    }
  }
  &.btn-warning
  {
    color: $btn-warning-color;
    @include hover-focus
    {
      background-color: darken($btn-warning-bg, $theme-color-interval);
      border-color: darken($btn-warning-bg, $theme-color-interval);
    }
  }
  &.btn-danger
  {
    @include hover-focus
    {
      background-color: darken($btn-danger-bg, $theme-color-interval);
      border-color: darken($btn-danger-bg, $theme-color-interval);
    }
  }
  &.btn-success
  {
    @include hover-focus
    {
      background-color: darken($btn-success-bg, $theme-color-interval);
      border-color: darken($btn-success-bg, $theme-color-interval);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] &
  {
    background: $btn-disabled-bg-color !important;
    border-color: $btn-link-disabled-color !important;
    color: $btn-link-disabled-color !important;
    opacity: 1 !important;
    &,
    &:hover,
    &:focus,
    &:active,
    &.active
    {
      cursor: default;
      background: $btn-disabled-bg-color !important;
      border-color: $btn-link-disabled-color !important;
      color: $btn-link-disabled-color !important;
      opacity: 1 !important;
    }
  }
  &.btn-outline-secondary,
  &.btn-outline-primary,
  &.btn-outline-info,
  &.btn-outline-warning,
  &.btn-outline-danger,
  &.btn-outline-success
  {
    transition: $transition;
    border: 1px solid $border-color;
  }
  &.btn-outline-secondary
  {
    color: $btn-secondary-color;
    box-shadow: inset 0 0 0 0 $btn-primary-bg;
    @include hover-focus-active
    {
      color: $btn-primary-color;
      box-shadow: inset $btn-outline-box-width 0 0 0 $btn-primary-bg;
      border-color: $btn-primary-bg !important;
    }
  }
  &.btn-outline-primary
  {
    box-shadow: inset 0 0 0 0 $btn-primary-bg;
    @include hover-focus-active
    {
      color: $white;
      box-shadow: inset $btn-outline-box-width 0 0 0 $btn-primary-bg;
      border-color: $btn-primary-bg;
    }
  }
  &.btn-outline-info
  {
    box-shadow: inset 0 0 0 0 $btn-info-bg;
    @include hover-focus-active
    {
      color: $white;
      box-shadow: inset $btn-outline-box-width 0 0 0 $btn-info-bg;
      border-color: $btn-info-bg;
    }
  }
  &.btn-outline-warning
  {
    box-shadow: inset 0 0 0 0 $btn-warning-bg;
    @include hover-focus
    {
      color: $white;
      box-shadow: inset $btn-outline-box-width 0 0 0 $btn-warning-bg;
    }
  }
  &.btn-outline-danger
  {
    box-shadow: inset 0 0 0 0 $btn-danger-bg;
    @include hover-focus-active
    {
      color: $white;
      box-shadow: inset $btn-outline-box-width 0 0 0 $btn-danger-bg;
    }
  }
  &.btn-outline-success
  {
    box-shadow: inset 0 0 0 0 $btn-success-bg;
    @include hover-focus-active
    {
      color: $white;
      box-shadow: inset $btn-outline-box-width 0 0 0 $btn-success-bg;
    }
  }
  // Badge on button
  .badge
  {
    position: absolute;
    top: -($spacer * .25);
    right:  -($spacer * .25);
    z-index: $zindex-btn + 1;
  }
}

.btn-search
{
  @extend .icon-search-white;
  min-width: 48px;
}

.btn-calendar
{
  @extend .icon-calendar-white;
  min-width: $input-height;
}

// Btn close
.btn-close
{
  @extend .close;
  @extend .close-sm;
  @extend .close-white;
  .btn-secondary &,
  &.btn-secondary
  {
    background-image: $icon-close;
    border: 0;
  }
}

.btn-search-reset
{
  display: none;
  background-image: $icon-close;
  @extend .icon-md;
  width: $spacer * 1.5;
  height: $spacer * 1.5;
  position: absolute;
  top: $spacer * .5;
  right: $spacer * 3.5;
  z-index: $zindex-fixed-dropdown;
  &:hover,
  &:focus
  {
    opacity: $close-hover-opacity;
  }
}

// btn left & right
.btn-left,
.btn-right
{
  @extend .custom-select-indicator;
  &:not(.btn-secondary)
  {
    @extend .custom-select-indicator-white;
    &:after
    {
      top: $spacer * .75 !important;
    }
  }
}
.btn-left
{
  padding-left: $spacer * 2;
  &:after
  {
    right: auto;
    left: $spacer * .5;
    transform: rotate(90deg);
    background-position: 2px 1px;
  }
}
.btn-right
{
  padding-right: $spacer * 2;
}

// Btn filter
.btn-filter
{
  text-transform: none;
  &:after
  {
    content: "";
    position: absolute;
    top: $spacer * .6;
    right: $spacer * .5;
    transition: $transition;
    outline: 0;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-position: right 50%;
    background-image: $icon-close-white;
  }
  &.btn-sm
  {
    &:after
    {
      top: $spacer * .4;
    }
  }
  &.btn-secondary
  {
    &:after
    {
      top: $spacer * .55;
      background-image: $icon-close;
    }
    &.btn-sm
    {
      &:after
      {
        top: $spacer * .35;
      }
    }
  }

  white-space: nowrap;
  padding-right: $spacer * 2;
  @include hover-focus-active
  {
    &:after
    {
      opacity: $close-hover-opacity;
    }
  }
}

// Filter group
.filter-group
{
  margin-bottom: -($spacer * .25);
  .btn
  {
    margin-right: $spacer * .25;
    margin-bottom: $spacer * .25;
  }
}

// btn-group
.btn-group
{
  .btn + .btn
  {
    margin-left: $spacer * .25;
  }
  &.btn-group-toggle
  {
    .btn + .btn
    {
      margin-left: 0;
    }
  }
}

.btn-group-vertical
{
  .btn + .btn
  {
    margin-top: $spacer * .25;
  }
  &.btn-group-toggle
  {
    .btn + .btn
    {
      margin-top: 0;
    }
  }
}

// Dropdowns
.dropdown-menu
{
  border: 0;
  box-shadow: $dropdown-box-shadow;
  max-width: $dropdown-max-width;
  ul
  {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &:before,
  &:after
  {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    display: block;
    position: absolute;
    left: $spacer * .4;
    z-index: $zindex-dropdown;
    border-width: 10px;
  }
  &:before
  {
    border-bottom-color: rgba(0,0,0,.1);
    top: -20px;
  }
  &:after
  {
    margin-left: 0;
    border-bottom-color: $white;
    top: -19px;
  }
  &[x-placement^="bottom-start"],
  &[x-placement^="bottom-end"],
  &.bottom
  {
    &:before
    {
      border-bottom-color: rgba(0,0,0,.1);
      top: -20px;
    }
    &:after
    {
      margin-left: 0px;
      border-bottom-color: $white;
      top: -19px;
    }
  }
  &[x-placement^="top-start"],
  &[x-placement^="top-end"]
  {
    top: -$spacer !important;
  }
  &[x-placement^="top-start"],
  &[x-placement^="top-end"],
  &.top
  {
    &:before
    {
      border-top-color: rgba(0,0,0,.1) !important;
      border-bottom-color: transparent !important;
      top: auto !important;
      bottom: -20px !important;
    }
    &:after
    {
      border-top-color: $white !important;
      border-bottom-color: transparent !important;
      top: auto !important;
      bottom: -19px !important;
    }
  }
  &.dropdown-menu-right
  {
    &:before,
    &:after
    {
      left: auto;
      right: $spacer * .4;
    }
  }
  .dropdown-item
  {
    font-family: $font-family-base;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    white-space: nowrap;
    text-decoration: none;
    text-transform: none;
    @include hover-focus-active
    {
      outline: 0 !important;
    }
    &.active
    {
      &:hover
      {
        background-color: $dropdown-link-active-hover-bg;
      }
    }
    .fa,
    .far,
    .fas,
    .fab,
    .fal
    {
      min-width: $spacer * 1.25;
      margin-right: $spacer * .5;
      text-align: center;
    }
    small
    {
      .fa,
      .far,
      .fas,
      .fab,
      .fal
      {
        min-width: 0;
      }
    }
    &.dropdown-item-show-more
    {
      text-align: center;
    }
  }
  &.dropdown-menu-wrap
  {
    min-width: none;
    max-width: none;
    @include media-breakpoint-up(sm)
    {
      min-width: $dropdown-wrap-min-width;
      max-width: $dropdown-max-width;
    }
    .dropdown-item
    {
      white-space: normal;
    }
  }
  .dropdown-header
  {
    @extend .h6;
    font-size: $font-size-sm;
    white-space: normal;
    margin: 0;
    padding: ($spacer * .75) $dropdown-item-padding-x;
  }
  .dropdown-divider
  {
    margin: 0;
  }
  > div
  {
    @extend .inner-y;
    max-height: $dropdown-scroll-max-height;
  }
}

.dropup
{
  .dropdown-toggle
  {
    &:after
    {
      border: 0 !important;
    }
    &[aria-expanded="true"]
    {
      &:after
      {
        transform: rotate(-180deg) !important;
      }
    }
  }
  .dropdown-menu
  {
    &:before
    {
      border-top-color: rgba(0,0,0,.1) !important;
      border-bottom-color: transparent !important;
      top: auto !important;
      bottom: -20px !important;
    }
    &:after
    {
      border-top-color: $white !important;
      border-bottom-color: transparent !important;
      top: auto !important;
      bottom: -19px !important;
    }
    &[x-placement^="top-start"],
    &[x-placement^="top-end"]
    {
      top: 0 !important;
    }
    &[x-placement^="bottom-start"],
    &[x-placement^="bottom-end"]
    {
      top: $spacer !important;
      &:before
      {
        border-top-color: transparent !important;
        border-bottom-color: rgba(0,0,0,.1) !important;
        top: -20px !important;
      }
      &:after
      {
        margin-left: 0px !important;
        border-top-color: transparent !important;
        border-bottom-color: $white !important;
        top: -19px !important;
      }
    }
  }
}

// Dropdown-menu multilevel
.dropdown-menu-multilevel
{
  ul
  {
    @include list-unstyled;
    margin: 0;
    overflow: hidden;
    li
    {
      position: relative;
      ul
      {
        .dropdown-item
        {
          padding-left: $spacer * 2;
        }
      }
    }
  }
  .dropdown-item[data-toggle="collapse"]
  {
    padding-right: $spacer * 3;
    position: relative;
    transition: $transition;
    &:hover
    {
      cursor: pointer;
    }
    @include hover-focus-active
    {
      outline: 0;
      text-decoration: none;
      background-color: $dropdown-link-hover-bg;
    }
    @extend .custom-select-indicator;
    &:after
    {
      top: $spacer * .7;
    }
    &[aria-expanded="true"]
    {
      background-color: $dropdown-link-hover-bg;
    }
    &.active
    {
      @extend .custom-select-indicator-white;
      background-color: $dropdown-link-active-bg;
    }
  }
}

.dropdown-menu-check
{
  .check,
  .custom-checkbox
  {
    position: absolute;
    top: $spacer * .5;
    left: $spacer;
    z-index: $zindex-dropdown + 1;
  }
  .dropdown-item
  {
    padding-left: $spacer * 3;
  }
  ul
  {
    li
    {
      position: relative;
      ul
      {
        .check,
        .custom-checkbox
        {
          left: $spacer * 2;
        }
        .dropdown-item
        {
          padding-left: $spacer * 4;
        }
      }
    }
  }
}

// Caret dropdown arrow
.dropdown-toggle
{
  position: relative;
  padding-right: $spacer * 2;
  .caret
  {
    display: none;
  }
  @extend .custom-select-indicator;
  &:not(.btn-secondary)
  {
    @extend .custom-select-indicator-white;
    &.btn-lg
    {
      &:after
      {
        top: $spacer;
      }
    }
    &.btn-sm
    {
      &:after
      {
        top: $spacer * .45;
      }
    }
  }
  &.btn-lg
  {
    &:after
    {
      top: $spacer * .9;
    }
  }
  &.btn-sm
  {
    &:after
    {
      top: $spacer * .4;
    }
  }
  &.btn-xs
  {
    &:after
    {
      display: none;
    }
  }
}

.dropdown-toggle-split
{
  .btn + .btn
  {
    margin-left: 0;
  }  
}

// Btn-collapse
.btn-collapse
{
  @extend .dropdown-toggle;
}

// Toast
.toast
{
  min-width: $toast-min-width;
  .toast-header
  {
    position: relative;
    padding-left: $spacer * .5;
    padding-right: $spacer * 2;
    .text-muted
    {
      color: $toast-text-muted-color !important;
    }
    .close
    {
      position: absolute;
      top: $spacer * .25;
      right: $spacer * .5;
      z-index: $zindex-popover + 1;
      @extend .close-white;
      @extend .close-sm;
    }
  } 
}

// Popover
.popover
{
  min-width: $popover-min-width;
  max-width: $popover-max-width;
  &.bs-popover-bottom
  {
    .arrow:after,
    .arrow:before
    {
      border-bottom-color: $popover-header-bg !important;
    }
  }
  .popover-title
  {
    color: $white;
    position: relative;
    padding-right: $spacer * 3;
  }
  .popover-body
  {
    position: relative;
    font-size: $font-size-base;
    @extend .inner-y;
    max-height: $popover-body-max-height;;
  }
  .close
  {
    position: absolute;
    top: 9px;
    z-index: ($zindex-popover + 1);
    right: $spacer;
    color: $white;
    &:before
    {
      color: $white;
    }
  }
  &[x-placement^="top"] {
    @extend .bs-popover-top;
    .arrow
    {
      top: 100%;
    }
  }
  &[x-placement^="right"] {
    @extend .bs-popover-right;
    .arrow
    {
      left: -8px;
    }
  }
  &[x-placement^="bottom"] {
    @extend .bs-popover-bottom;
    .arrow
    {
      top: -8px;
    }
  }
  &[x-placement^="left"] {
    @extend .bs-popover-left;
    .arrow
    {
      right: -8px;
    }
  }
}

// Tooltip
.tooltip
{
  font-size: $font-size-sm;
}

// Panels and wells
.panel,
.well
{
  position: relative;
  display: block;
  background-color: $white;
  padding: $spacer * 2;
  @extend .clearfix;
  @include media-breakpoint-down(xs)
  {
    padding: $spacer;
  }
  &.well-default,
  &.well-secondary
  {
    background-color: $gray-superlight;
  }
  &.well-primary,
  &.well-info
  {
    background-color: $primary;
  }
  &.well-warning
  {
    background-color: $warning;
  }
  &.well-primary,
  &.well-info,
  &.well-warning
  {
    @extend .white-text;
  }
  > p:last-child
  {
    margin-bottom: 0;
  }
}

// Forms
fieldset
{
  @include clearfix;
  padding: $fieldset-padding;
  margin-bottom: $spacer;
  background-color: $fieldset-bg;
  @include media-breakpoint-down(sm)
  {
    padding: $fieldset-padding-sm;
  }
  .form-group,
  .alert
  {
    &:last-child
    {
      margin-bottom: 0;
    }
  }
  .table 
  {
    border-color: $input-border-color;
    th,
    td
    {
      border-color: $input-border-color;
    }
  }
}

// Form group
.form-group
{
  &.row
  {
    .col-form-label
    {
      text-align: right;
      padding-right: 0;
      padding-bottom: 0;
      white-space: nowrap;
      @include media-breakpoint-down(md)
      {
        text-align: left;
        padding-top: 0;
        margin-bottom: $spacer * .25;
      }
    }
  }
  .card.border
  {
    border-color: $input-border-color !important;
  }
}

.custom-control
{
  + .custom-control
  {
    margin-top: $spacer * .5;
  }
}

// Form-control
input[plaintext],
.form-control-plaintext
{
  box-shadow: none !important;
  outline: none !important;
  color: $text-color !important;
}

// Form-control
.form-control
{
  font-family: $input-font-family;
  @include focus-box-shadow;
  // ios fix
  &[type="date"],
  &[type="month"],
  &[type="week"],
  &[type="time"],
  &[type="datetime-local"]
  {
    -webkit-appearance: textfield;
  }
  &[disabled],
  &[readonly],
  fieldset[disabled] &
  {
    background-clip: border-box !important;
    &:placeholder
    {
      color: $disabled-color;
    }
  }
  &.form-control-xs
  {
    font-size: $input-font-size-xs;
    line-height: $input-line-height-xs;
    padding: $input-padding-x-xs $input-padding-y-xs;
  }
  &.form-control-sm
  {
    font-size: $input-font-size-sm;
    line-height: $input-line-height-sm;
  }
  &.form-control-lg
  {
    font-size: $input-font-size-lg;
    line-height: $input-line-height-lg;
  }
}

// File browser, custom-file
.custom-file
{
  .custom-file-label
  {
    &:after
    {
      box-sizing: border-box;
      transition: $transition;
      font-family: $headings-font-family;
      font-weight: normal;
      font-size: $btn-font-size;
      line-height: $btn-line-height;
      text-transform: $btn-text-transform;
      text-decoration: none;
      border-style: none;
      color: $btn-primary-color;
      background-color: $btn-primary-bg;
    }
  }
  .custom-file-input
  {
    &:hover
    {
      cursor: pointer;
    }
  }
  .custom-file-input:hover ~ .custom-file-label::after,
  .custom-file-input:focus ~ .custom-file-label::after,
  .custom-file-input:active ~ .custom-file-label::after
  {
    background-color: darken($btn-primary-bg, $theme-color-interval) !important;
  }
  .custom-file-input:focus ~ .custom-file-label
  {
    box-shadow: $focus-box-shadow;
    transition: $focus-transition;
  }
}

// Textarea
textarea.form-control
{
  min-height: $spacer * 5;
}

// Selects
select.form-control
{
  cursor: pointer;
}

select.form-control:not([size]):not([multiple])
{
  @extend .custom-select;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  box-shadow: none;
  @include focus-box-shadow;
  &.form-control
  {
    font-size: $input-font-size;
    line-height: $input-line-height;
    height: $input-height;
  }
  &.form-control-sm
  {
    font-size: $input-font-size-sm;
    line-height: $input-line-height-sm;
    height: $input-height-sm;
  }
  &.form-control-lg
  {
    font-size: $input-font-size-lg;
    line-height: $input-line-height-lg;
    height: $input-height-lg;
  }
}

select.form-control[multiple]
{
  @extend .inner-y;
  max-height: 90px;
}

select
{
  &[disabled],
  &[readonly],
  fieldset[disabled] &
  {
    &:hover
    {
      cursor: default;
    }
  }
}

// Custom range
.custom-range
{
  height: auto !important; // IE fix
  &:hover
  {
    cursor: pointer;
  }
}

// Checkboxes & radios
.form-check-input,
input[type="checkbox"],
input[type="radio"]
{
  margin-right: .5rem;
}

.custom-control
{
  .custom-control-label
  {
    cursor: pointer;
    &:before,
    &:after
    {
      border: 0;
    }
    @include hover-focus-active
    {
      &:before
      {
        background-color: darken($custom-control-indicator-bg, $theme-color-interval) !important;
      }
    }
  }
  input[disabled] + .custom-control-label,
  input.disabled + .custom-control-label
  {
    @extend .pointer-events-none;
    cursor: default !important;
  }
  .custom-control-input:focus ~ .custom-control-label::before
  {
    background-color: darken($custom-control-indicator-bg, $theme-color-interval) !important;
  }
  &.custom-radio
  {
    .custom-control-input:checked ~ .custom-control-label::after
    {
      top: .1rem;
    }
  }
}

// Pre 5.0.0 checkbox radios
.check,
.radio
{
  position: relative;
  label
  {
    margin: 0 !important;
    padding: ($spacer * .5) 0 0 ($spacer * 1.55) !important;
    min-height: $spacer * 1.5;
    cursor: pointer;
    @extend .user-select-none;
    @include hover-focus-active
    {
      &:before
      {
        background-color: darken($custom-control-indicator-bg, $theme-color-interval) !important;
      }
    }
  }
  input
  {
    @include visuallyhidden();
  }
  input + label:before,
  input + label:after
  {
    position: absolute;
    z-index: $zindex-btn;
    display: inline-block;
    cursor: pointer;
    transition: $transition;
    content: "";
  }
  input + label:before
  {
    top: $spacer * .55;
    left: 0;
    width: 22px;
    height: 22px;
    content: "";
    border-radius: $custom-checkbox-indicator-border-radius;
    background-color: $custom-control-indicator-bg;
  }
  input[disabled] + label,
  input.disabled + label
  {
    color: $custom-control-label-disabled-color !important;
    @extend .pointer-events-none;
    cursor: default !important;
  }
  input[disabled] + label:before,
  input.disabled + label:before
  {
    background-color: $custom-control-label-disabled-color !important;
  }
}
.check
{
  input:checked + label:after
  {
    top: $spacer * .7;
    left: 2px;
    @extend .icon-check-white;
  }
}
.radio
{
  input + label:before
  {
    border-radius: 50%;
  }
  input:checked + label:after
  {
    top: $spacer * .75;
    left: 2px;
    @extend .icon-check-white;
  }
}

// Form validation
.form-group,
.check,
.radio,
.custom-checkbox,
.custom-radio
{
  &.has-warning
  {
    label
    {
      color: $warning !important;
      &:before
      {
        background-color: $btn-warning-bg !important;
      }
      @include hover-focus-active
      {
        &:before
        {
          background-color: darken($btn-warning-bg, $theme-color-interval) !important;
        }
      }
    }
    .custom-control-input:focus ~ .custom-control-label::before
    {
      background-color: darken($btn-warning-bg, $theme-color-interval) !important;
    }
    .form-control
    {
      border-color: $warning;
    }
    .form-control-feedback
    {
      color: $warning;
    }
  }
  &.has-danger
  {
    label
    {
      color: $danger !important;
      &:before
      {
        background-color: $btn-danger-bg !important;
      }
      @include hover-focus-active
      {
        &:before
        {
          background-color: darken($btn-danger-bg, $theme-color-interval) !important;
        }
      }
    }
    .custom-control-input:focus ~ .custom-control-label::before
    {
      background-color: darken($btn-danger-bg, $theme-color-interval) !important;
    }
    .form-control
    {
      border-color: $danger;
    }
    .form-control-feedback
    {
      color: $danger;
    }
  }
  &.has-success
  {
    label
    {
      color: $success !important;
      &:before
      {
        background-color: $btn-success-bg !important;
      }
      @include hover-focus-active
      {
        &:before
        {
          background-color: darken($btn-success-bg, $theme-color-interval) !important;
        }
      }
    }
    .custom-control-input:focus ~ .custom-control-label::before
    {
      background-color: darken($btn-success-bg, $theme-color-interval) !important;
    }
    .form-control
    {
      border-color: $success;
    }
    .form-control-feedback
    {
      color: $success;
    }
  }
}

// Alert
.alert
{
  border-width: 2px !important;
  &.alert-success
  {
    color: $state-success-text;
    border-color: $state-success-border;
    background-color: $state-success-bg;
  }
  &.alert-primary,
  &.alert-info
  {
    color: $state-primary-text;
    border-color: $state-primary-border;
    background-color: $state-primary-bg;
  }
  &.alert-warning
  {
    color: $state-warning-text;
    border-color: $state-warning-border;
    background-color: $state-warning-bg;
  }
  &.alert-danger
  {
    color: $state-danger-text;
    border-color: $state-danger-border;
    background-color: $state-danger-bg;
  }
  .close
  {
    color: inherit !important;
    margin-top: -1px;
  }
  a
  {
    color: inherit !important;
    text-decoration: underline !important;
    font-weight: normal !important;
  }
}

// Jumbotron
.jumbotron
{
  @extend .white-text;
  h1
  {
    @include media-breakpoint-down(lg)
    {
      font-size: $display4-size;
    }
    @include media-breakpoint-down(sm)
    {
      font-size: $h2-font-size;
    }
  }
  .well
  {
    @extend .well-info;
    margin-bottom: 0;
    background-color: transparent;
    border: 1px solid $white;
  }
}

// Navs
.nav
{
  .nav-item,
  > li
  {
    @extend .nav-item;
    text-transform: $nav-text-transform;
    .nav-link,
    > a
    {
      @extend .nav-link;
      outline: 0 !important;
      transition: $transition;
      color: $nav-link-color;
      text-decoration: none;
      min-height: 40px;
      @include hover-focus-active
      {
        color: $nav-link-hover-color;
        text-decoration: none;
        background-color: $nav-link-hover-bg;
        outline: 0;
      }
      &.disabled,
      &[disabled]
      {
        cursor: $cursor-disabled;
        color: $nav-disabled-link-color !important;
      }
    }
  }
  &.nav-tabs-sm
  {
    a,
    .page-link
    {
      position: relative;
      background-color: transparent !important;
      border: 0 !important;
      white-space: nowrap;
      color: $link-color !important;
      &:before,
      &:focus:before
      {
        position: absolute;
        bottom: 0;
        left: 1px;
        right: 1px;
        height: 1px;
        background: $nav-tabs-link-active-color;
        content: "";
        opacity: 0;
        transition: color .2s, height .2s, opacity .2s, transform .2s;
        transform: translateY(-5px);
      }
      &:hover:before,
      &:focus:before,
      &.active:before
      {
        display: inline-block;
        height: 2px;
        opacity: 1;
        transform: translateY(0)
      }
      &:hover,
      &:focus,
      &.active
      {
        color: $nav-tabs-link-active-color !important;
      }
      &.disabled,
      &[disabled]
      {
        color: $disabled-color !important;
        &:hover:before,
        &:focus:before
        {
          display: none;
        }
      }
    }
  }
  &.nav-tabs
  {
    @extend .nav-tabs;
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
    > li,
    .nav-item
    {
      margin-bottom: -$nav-tabs-border-width;
      > a,
      &.nav-link
      {
        padding: $nav-link-padding-y $nav-link-padding-x;
        border: $nav-tabs-border-width solid transparent;
        @include hover-focus
        {
          border-color: $nav-tabs-link-hover-border-color;
          background-color: $nav-link-hover-bg;
        }
      }
      &.active a,
      a.active,
      a.open,
      &.nav-link.active
      {
        @include plain-hover-focus
        {
          color: $nav-tabs-link-active-color;
          background-color: $nav-tabs-link-active-bg;
          border-color: $nav-tabs-link-active-border-color;
        }
      }
    }
  }
  &.nav-pills
  {
    > li
    {
      &:first-child
      {
        a,
        .nav-link
        {
          border-left: 1px solid $nav-border-color;
        }
      }
    }
    .nav-link,
    a
    {
      position: relative;
      padding: $nav-link-padding-y $nav-link-padding-x;
      border-right: 1px solid $nav-border-color;
      &.disabled,
      &[disabled]
      {
        color: $nav-disabled-link-color !important;
        @include plain-hover-focus
        {
          background-color: transparent;
        }
      }
    }
    .active > a,
    a.active,
    .nav-link.active,
    .open a
    {
      border-right: 1px solid $nav-border-color;
      padding-left: $spacer * 2;
      @include plain-hover-focus
      {
        color: $nav-pills-link-active-color;
        background-color: $nav-pills-link-active-bg;
      }
      @extend .custom-select-indicator;
      @extend .custom-select-indicator-white;
      &:after
      {
        top: $spacer * .7;
        left: $spacer * .75;
      }
    }
    &.flex-column
    {
      li
      {
        &:first-child
        {
          .nav-link,
          a
          {
            border-left: 0;
          }
        }
        a
        {
          width: 100%;
        }
      }
      > li
      {
        &:first-child
        {
          .nav-link,
          a
          {
            border-top: 1px solid $nav-border-color;
          }
        }
      }
      .nav-link,
      a
      {
        border-right: 0;
        border-bottom: 1px solid $nav-border-color;
      }
      .active > a,
      a.active,
      .nav-link.active,
      .open a
      {
        border-right: 0 !important;
      }
      ul
      {
        @extend .list-unstyled;
      }
      ul
      {
        .nav-link,
        a
        {
          padding-left: $spacer * 2;
        }
        .active > a,
        a.active,
        .nav-link.active,
        .open a
        {
          padding-left: $spacer * 3;
          background-color: $nav-pills-stacked-link-active-bg;
          color: $nav-pills-stacked-link-active-color;
          @include hover-focus
          {
            background-color: $nav-link-hover-bg;
          }
          &:after
          {
            @extend .custom-select-indicator;
            left: $spacer * 1.75;
          }
        }
        ul
        {
          .nav-link,
          a
          {
            padding-left: $spacer * 3;
          }
          .active > a,
          a.active,
          .nav-link.active,
          .open a
          {
            padding-left: $spacer * 4;
            &:after
            {
              left: $spacer * 2.75;
            }
          }
          ul
          {
            .nav-link,
            a
            {
              padding-left: $spacer * 4;
            }
            .active > a,
            a.active,
            .nav-link.active,
            .open a
            {
              padding-left: $spacer * 5;
              &:after
              {
                left: $spacer * 3.75;
              }
            }
          }
        }
      }
    }
  }
}

// Pagination
.pagination,
.pagination-sm,
.pagination-lg
{
  .page-item,
  > li
  {
    @extend .page-item;
    .page-link,
    > a
    {
      position: relative;
      min-width: 40px;
      height: 40px;
      border: 0;
      font-size: $font-size-base;
      white-space: nowrap;
      outline: 0 !important;
      &:focus
      {
        color: $pagination-hover-color;
      }
      &:before,
      &:focus:before
      {
        position: absolute;
        bottom: 0;
        left: 1px;
        right: 1px;
        height: 1px;
        background: $pagination-active-border-color;
        content: "";
        opacity: 0;
        transition: color .2s, height .2s, opacity .2s, transform .2s;
        transform: translateY(-5px);
      }
    }
    .page-link:hover:before,
    .page-link:focus:before,
    .page-link.active:before,
    > a:hover:before,
    > a:focus:before,
    > a.active:before,
    &.active .page-link:before,
    &.active > a:before
    {
      display: inline-block;
      height: 2px;
      opacity: 1;
      transform: translateY(0)
    }
    &.active .page-link,
    &.active > a,
    > a.active 
    {
      color: $pagination-active-color;
    }
    &.disabled .page-link,
    &.disabled > a,
    > a.disabled 
    {
      &:hover:before,
      &:focus:before
      {
        display: none;
      }
    }
    &:first-child
    {
      .page-link,
      > a
      {
        @extend .custom-select-indicator;
        &:after
        {
          transform: rotate(90deg);
          top: $spacer * .75;
          right: $spacer * .7;
        }
      }
    }
    &:last-child
    {
      .page-link,
      > a
      {
        @extend .custom-select-indicator;
        &:after
        {
          transform: rotate(-90deg);
          top: $spacer * .7;
          right: $spacer * .7;
        }
      }
    }
    &[disabled]:first-child,
    &[disabled]:last-child,
    &.disabled:first-child,
    &.disabled:last-child
    {
      .page-link,
      > a
      {
        &:after
        {
          @extend .custom-select-disabled;
        }
      }
    }
  }
}

// Badge
.badge
{
  align-self: center;
  background-color: $badge-default-bg;
  line-height: $badge-line-height;
  min-height: $badge-height;
  max-height: $badge-height;
  min-width: $badge-height;
  color: $badge-color;
  @extend .badge-pill;
  border-radius: $badge-pill-border-radius;
}
.badge-sm
{
  font-size: $badge-font-size-sm;
  line-height: $badge-line-height-sm;
  padding: $badge-padding-y-sm $badge-padding-x-sm;
  min-height: $badge-height-sm;
  max-height: $badge-height-sm;
  min-width: $badge-height-sm;
}
.badge-lg
{
  font-size: $badge-font-size-lg;
  line-height: $badge-line-height-lg;
  padding: $badge-padding-y-lg $badge-padding-x-lg;
  min-height: $badge-height-lg;
  max-height: $badge-height-lg;
  min-width: $badge-height-lg;
}
.badge-primary
{
  background-color: $badge-primary-bg;
}
.badge-info
{
  background-color: $badge-info-bg;
}
.badge-warning
{
  background-color: $badge-warning-bg;
}
.badge-danger
{
  background-color: $badge-danger-bg;
}
.badge-success
{
  background-color: $badge-success-bg;
}
.badge-outline
{
  color: $text-color;
  background-color: transparent;
  box-shadow: inset 0 0 0 .125rem $border-color;
}

// Wizard steps
.steps
{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  .badge
  {
    @extend .badge-lg;
    @extend .badge-outline;
    position: relative;
  }
  .step-header
  {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    margin: 15px ($spacer * 2) 15px ($spacer * .5);
    &:after
    {
      content: "";
      display: inline-block;
      background-color: $border-color;
      width: $spacer;
      height: 2px;
      position: absolute;
      top: 13px;
      left: 100%;
      margin-left: ($spacer * .5);
    }
  }
  &.steps-vertical
  {
    flex-direction: column;
    .badge
    {
      &:after
      {
        content: "";
        display: inline-block;
        background-color: $border-color;
        width: 2px;
        height: $spacer;
        position: absolute;
        top: ($spacer * 2.25);
        right: 15px;
      }
    }
    .step-header
    {
      margin-right: $spacer;
      &:after
      {
        display: none;
      }
    }
  }
  > li
  {
    display: flex;
    padding: 1px 0;
    &:last-child
    {
      .badge,
      .step-header
      {
        &:after
        {
          display: none;
        }
      }
    }
  }
  .done
  {
    .badge
    {
      @extend .badge-primary;
      box-shadow: none;
    }
  }
  .active
  {
    .badge
    {
      @extend .badge-dark;
      box-shadow: none;
    }
    .step-header
    {
      font-family: $font-family-heavy;
    }
  }
}
@include media-breakpoint-down(sm)
{
  .steps:not(.steps-vertical)
  {
    .badge
    {
      margin-right: ($spacer * 1.5);
      &:after
      {
        content: "";
        display: inline-block;
        background-color: $border-color;
        width: $spacer;
        height: 2px;
        position: absolute;
        top: 15px;
        left: 100%;
        margin-left: ($spacer * .25);
      }
    }
    .step-header
    {
      display: none;
    }
  }
}

// Breadcrumb
.breadcrumb
{
  @extend .list-unstyled;
  li
  {
    @extend .breadcrumb-item;
  }
  .breadcrumb-item,
  li > a
  {
    text-decoration: none;
    &:hover:not(.active),
    &:focus:not(.active)
    {
      text-decoration: underline;
    }
  }
}

// List group
.list-group
{
  .list-group-item
  {
    position: relative;
    width: 100%;
    color: $list-group-link-color;
    text-align: inherit;
    text-transform: uppercase;
    text-decoration: none;
    transition: $transition;
    padding-right: $spacer;
    border-left: 0;
    border-right: 0;
    @include hover-focus
    {
      color: $list-group-link-hover-color;
      text-decoration: none;
      background-color: $list-group-hover-bg;
      outline: 0 !important;
    }
    .list-group-item-heading
    {
      @extend .h5;
      margin: 0;
    }
    @extend .custom-select-indicator;
    &.active
    {
      color: $component-active-color;
      &:after
      {
        @extend .custom-select-indicator-white;
      }
      .list-group-item-heading
      {
        color: $component-active-color;
      }
      @include hover-focus-active
      {
        background-color: $component-active-bg;
        color: $component-active-color;
        cursor: $cursor-disabled;
      }
    }
    &.list-group-item-action
    {
      padding-right: $spacer;
      &:after
      {
        display: none;
      }
    }
    .list-group-item-heading
    {
      width: 100%;
    }
    p
    {
      width: 100%;
      font-family: $font-family-regular;
      text-transform: none;
      margin-bottom: $spacer * .25;
    }
    &.disabled,
    &[disabled]
    {
      color: $list-group-disabled-color;
      cursor: $cursor-disabled;
    }
  }
}

// Card
.card
{
  // Ratio img
  > figure
  {
    height: 100%;
    margin: 0;
    height: 0;
    overflow: hidden;
    padding-top: 66.66%; // Ratio 3:2 img
    // padding-top: 56.25%; // Ratio 16:9 img
    position: relative;
    > img
    {
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .card-footer
  {
    display: flex !important;
    flex-direction: row !important;
    border-top: 1px solid $white !important;
    padding: 0 !important;
    .btn
    {
      @extend .btn-primary;
      @extend .btn-sm;
      white-space: pre-wrap;
      text-align: left;
      position: relative;
      padding-left: $spacer;
      padding-right: $spacer;
      flex-grow: 1;
      & + .btn
      {
        border-left: 1px solid $white !important;
      }
    }
  }
}

// Download
.download
{
  @extend .well;
  @extend .well-warning;
  margin-bottom: $spacer;
  *
  {
    color: #fff;
  }
  code
  {
    background-color: rgba(255,255,255,.2);
  }
}

// Modal
.modal
{
  .modal-content
  {
    .modal-header
    {
      position: relative;
      background-color: $modal-header-bg;
      padding: $modal-header-padding;
      min-height: $modal-header-min-height;
      display: flex;
      flex-direction: row;
      .modal-title
      {
        font-size: $h4-font-size;
        line-height: $h4-line-height;
        color: $modal-header-color;
        margin: 0;
        flex: 1;
      }
      .close
      {
        @extend .close-white;
        position: absolute;
        top: $spacer * .7;
        right: $spacer *.5;
        z-index: $zindex-modal + 1;
        margin: 0;
        color: $white;
        &:before
        {
          color: $white;
        }
        @include media-breakpoint-up(sm)
        {
          &:before
          {
            font-size: $h2-font-size;
          }
        }
      }
      .modal-header-btn-group,
      .btn-group
      {
        position: absolute;
        top: $spacer * .5 !important;
        right: $spacer * 4 !important;
        @include media-breakpoint-down(md)
        {
          right: $spacer * 3 !important;
        }
        .btn + .btn
        {
          margin-left: $spacer;
        }
        .btn-icon,
        .btn-primary
        {
          height: $spacer * 2;
          padding: 0;
          background: transparent !important;
          color: $white;
          transition: $transition;
          @include hover-focus
          {
            opacity: $close-hover-opacity;
          }
          .fa,
          .far,
          .fas
          {
            @extend .icon-fa-lg;
          }
        }
      }
    }
    .modal-body
    {
      padding: $spacer;
      .dialog
      {
        width: 100%;
        min-height: 90px;
        display: table;
        .dialog-body
        {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          h1, h2, h3, h4, h5
          {
            font-size: $h5-font-size;
            margin: 0;
          }
        }
      }
    }
    .modal-footer
    {
      width: 100%;
      border-top: 1px $modal-footer-border-color solid;
      padding: 0;
      display: flex;
      flex-direction: row;
      .btn
      {
        flex: 1;
        justify-content: center;
        text-align: center;
        margin: 0;
        border: 0;
        min-height: 40px;
      }
      .btn + .btn
      {
        margin: 0;
      }
    }
    .dropdown-menu
    {
      z-index: $zindex-dropdown-modal;
    }
  }
}

// Modal page
.modal-page
{
  height: 100vh;
  min-height: 100%;
  .modal-dialog
  {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    height: 100vh;
    min-height: 100%;
    max-width: 100% !important;
    @include media-breakpoint-up(lg)
    {
      max-width: 960px !important;
      margin-left: auto !important;
    }
    .modal-content
    {
      height: 100vh;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0;
      overflow: hidden;
      .modal-header
      {
        .modal-title
        {
          margin: 0;
          flex: 1;
        }
        @include media-breakpoint-up(md)
        {
          padding: ($spacer * .815) $spacer-x;
          .btn-group
          {
            top: $spacer * .8 !important;
          }
          .close
          {
            top: $spacer;
            right: $spacer * 1.5;
          }
        }
        @include media-breakpoint-down(md)
        {
          .close
          {
            right: $spacer * .5;
          }
        }
      }
      .modal-body
      {
        flex: 1;
        @extend .inner-y;
        @include media-breakpoint-up(md)
        {
          padding: $spacer-y $spacer-x;
        }
      }
    }
  }
}

.modal:not(.show).modal-page .modal-dialog
{
  @include media-breakpoint-up(sm)
  {
    transform: translate3d(100%, 0, 0);
  }
  .project-header
  {
    opacity: 0;
  }
}

// Progress bar navbar
.progress-navbar
{
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zindex-progressjs;
  width: 100%;
  max-height: $spacer * .25;
  transition: $transition;
  @include media-breakpoint-up(lg)
  {
    top: $navbar-height;
  }
  svg
  {
    width: 100%;
    height: $spacer * .25;
    > path:last-child
    {
      stroke: $blue-dark;
    }
  }
  .progressbar-text
  {
    display: none;
  }
}

// Accordion Collapse
.accordion
{
  border-top: 1px solid $nav-border-color;
  .card
  {
    margin-bottom: 0;
    .card-header
    {
      background-color: transparent;
      padding: 0;
      border-bottom: 1px solid $nav-border-color;
      a[data-toggle="collapse"]
      {
        @extend .h5;
        margin: 0;
        padding: ($spacer * .5) $spacer;
        padding-right: $spacer;
        color: $nav-link-color;
        &:after
        {
          top: $spacer * .75;
        }
      }
    }
    .card-body
    {
      padding: $spacer;
      border-bottom: 1px solid $nav-border-color;
      p:last-child
      {
        margin-bottom: 0;
      }
    }
  }
  a[data-toggle="collapse"]
  {
    display: block;
    position: relative;
    text-decoration: none;
    transition: $transition;
    color: $nav-link-color;
    &:hover
    {
      cursor: pointer;
    }
    @include hover-focus
    {
      outline: 0 !important;
      text-decoration: none;
      background: $nav-link-hover-bg !important;
    }
    @extend .custom-select-indicator;
    &[aria-expanded="true"]
    {
      color: $component-color !important;
      @include hover-focus
      {
        background: $nav-link-hover-bg !important;
      }
    }
  }
}

// Margin child
.m-l-child
{
  > * + *
  {
    margin-left: $spacer;
  }
}

.m-r-child
{
  > * + *
  {
    margin-right: $spacer;
  }
}

.m-t-child
{
  > * + *
  {
    margin-top: $spacer;
  }
}

.m-b-child
{
  > * + *
  {
    margin-bottom: $spacer;
  }
}

.m-l-child-sm
{
  > * + *
  {
    margin-left: $spacer * .25;
  }
}

.m-r-child-sm
{
  > * + *
  {
    margin-right: $spacer * .25;
  }
}

.m-t-child-sm
{
  > * + *
  {
    margin-top: $spacer * .25;
  }
}

.m-b-child-sm
{
  > * + *
  {
    margin-bottom: $spacer * .25;
  }
}

.spinner-border
{
  line-height: 0;
  border-color: $icon-color;
  border-width: ($spacer * .2);
  border-bottom: ($spacer * .2) solid transparent !important;
  border-left: ($spacer * .2) solid transparent !important;
  border-right: ($spacer * .2) solid transparent !important;
  &:before
  {
    display: inline-block;
    content: "";
    width: $spacer * 2;
    height: $spacer * 2;
    border: ($spacer * .2) solid $icon-color;
    border-radius: 50%;
    margin: -($spacer * .2);
    opacity: .25;
  }
  &.spinner-border-white
  {
    border-color: $white;
    &:before
    {
      border-color: $white;
    }
  }
  &.spinner-border-sm
  {
    vertical-align: text-top;
    width: ($spacer * 1.25);
    height: ($spacer * 1.25);
    border-width: ($spacer * .1);
    border-bottom: ($spacer * .1) solid transparent !important;
    border-left: ($spacer * .1) solid transparent !important;
    border-right: ($spacer * .1) solid transparent !important;
    &:before
    {
      width: ($spacer * 1.25);
      height: ($spacer * 1.25);
      border-width: ($spacer * .1) !important;
      margin: -($spacer * .1);
    }
  }
}

// Search hit
.search-hit
{
  @extend mark;
}

// Offcanvas
.offcanvas
{
  transition: $transition;
  @include media-breakpoint-up(lg)
  {
    display: block !important;
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important;
  }
  @include media-breakpoint-down(md)
  {
    &.fade:not(.show)
    {
      transform: translate3d(25%, 0, 0) !important;
    }
    .offcanvas-header
    {
      position: relative;
      .close
      {
        position: absolute;
        top: 0;
        right: 0;
        z-index: ($zindex-modal + 1);
      }
    }
    position: fixed;
    z-index: $zindex-modal;
    top: 0;
    bottom: 0;
    right: 0;
    background: $gray-superlight;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $spacer;
    box-shadow: $box-shadow-lg;
    height: auto;
    width: 100vw;
    @include media-breakpoint-up(sm)
    {
      width: $spacer * 20;
    }
  }
}