// Sweco color classes

// Black
.black{background-color: $black;}
.black-ich{background-color: $black-ich;}
.black-light{background-color: $black-light;}
.black-lightning{background-color: $black-lightning;}
.black-lighter{background-color: $black-lighter;}
.black-ultralight{background-color: $black-ultralight;}
.black-superlight{background-color: $black-superlight;}

.black-color{color: $black;}
.black-ich-color{color: $black-ich;}
.black-light-color{color: $black-light;}
.black-lightning-color{color: $black-lightning;}
.black-lighter-color{color: $black-lighter;}
.black-ultralight-color{color: $black-ultralight;}
.black-superlight-color{color: $black-superlight;}


// Sweco Gray Dark
.dark-gray{background-color: $dark-gray;}
.dark-gray-ich{background-color: $dark-gray-ich;}
.dark-gray-light{background-color: $dark-gray-light;}
.dark-gray-lighter{background-color: $dark-gray-lighter;}
.dark-gray-ultralight{background-color: $dark-gray-ultralight;}
.dark-gray-superlight{background-color: $dark-gray-superlight;}
.gray-dark{background-color: $dark-gray;}

.dark-gray-color{color: $dark-gray;}
.dark-gray-ich-color{color: $dark-gray-ich;}
.dark-gray-light-color{color: $dark-gray-light;}
.dark-gray-lighter-color{color: $dark-gray-lighter;}
.dark-gray-ultralight-color{color: $dark-gray-ultralight;}
.dark-gray-superlight-color{color: $dark-gray-superlight;}
.gray-dark-color{color: $dark-gray;}

// Sweco Gray
.gray{background-color: $gray;}
.gray-ich{background-color: $gray-ich;}
.gray-light{background-color: $gray-light;}
.gray-lighter{background-color: $gray-lighter;}
.gray-ultralight{background-color: $gray-ultralight;}
.gray-superlight{background-color: $gray-superlight;}

.gray-color{color: $gray;}
.gray-ich-color{color: $gray-ich;}
.gray-light-color{color: $gray-light;}
.gray-lighter-color{color: $gray-lighter;}
.gray-ultralight-color{color: $gray-ultralight;}
.gray-superlight-color{color: $gray-superlight;}

// Sweco Gray Light
.light-gray{background-color: $light-gray;}
.light-gray-ich{background-color: $light-gray-ich;}
.light-gray-light{background-color: $light-gray-light;}
.light-gray-lighter{background-color: $light-gray-lighter;}
.light-gray-ultralight{background-color: $light-gray-ultralight;}
.light-gray-superlight{background-color: $light-gray-superlight;}

.light-gray-color{color: $light-gray;}
.light-gray-ich-color{color: $light-gray-ich;}
.light-gray-light-color{color: $light-gray-light;}
.light-gray-lighter-color{color: $light-gray-lighter;}
.light-gray-ultralight-color{color: $light-gray-ultralight;}
.light-gray-superlight-color{color: $light-gray-superlight;}

// Sweco Ochre
.ochre-dark{background-color: $ochre-dark;}
.ochre{background-color: $ochre;}
.ochre-ich{background-color: $ochre-ich;}
.ochre-light{background-color: $ochre-light;}
.ochre-lighter{background-color: $ochre-lighter;}
.ochre-ultralight{background-color: $ochre-ultralight;}

.ochre-dark-color{color: $ochre-dark;}
.ochre-color{color: $ochre;}
.ochre-ich-color{color: $ochre-ich;}
.ochre-light-color{color: $ochre-light;}
.ochre-lighter-color{color: $ochre-lighter;}
.ochre-ultralight-color{color: $ochre-ultralight;}

// Sweco Pink
.pink-darker{background-color: $pink-darker;}
.pink-dark{background-color: $pink-dark;}
.pink{background-color: $pink;}
.pink-ich{background-color: $pink-ich;}
.pink-light{background-color: $pink-light;}
.pink-lighter{background-color: $pink-lighter;}
.pink-ultralight{background-color: $pink-ultralight;}

.pink-darker-color{color: $pink-darker;}
.pink-dark-color{color: $pink-dark;}
.pink-color{color: $pink;}
.pink-ich-color{color: $pink-ich;}
.pink-light-color{color: $pink-light;}
.pink-lighter-color{color: $pink-lighter;}
.pink-ultralight-color{color: $pink-ultralight;}

// Sweco Blue
.blue-darker{background-color: $blue-darker;}
.blue-dark{background-color: $blue-dark;}
.blue{background-color: $blue;}
.blue-ich{background-color: $blue-ich;}
.blue-light{background-color: $blue-light;}
.blue-lighter{background-color: $blue-lighter;}
.blue-ultralight{background-color: $blue-ultralight;}

.blue-darker-color{color: $blue-darker;}
.blue-dark-color{color: $blue-dark;}
.blue-color{color: $blue;}
.blue-ich-color{color: $blue-ich;}
.blue-light-color{color: $blue-light;}
.blue-lighter-color{color: $blue-lighter;}
.blue-ultralight-color{color: $blue-ultralight;}

// Sweco green
.green-dark{background-color: $green-dark;}
.green{background-color: $green;}

.green-dark-color{color: $green-dark;}
.green-color{color: $green;}

// Success
.success{background-color: $success;}
.success-color{color: $success;}

// Red
.red{background-color: $red;}
.red-color{color: $red;}

// White
.white{background-color: $white;}
.white-color{color: $white;}

// Link color
.link-color{color: $link-color};

// Text color
.text-color{color: $text-color};


// Sweco - for demo colors
.colors
{
  ul
  {
    @extend .list-unstyled;
    li
    {
      display: block;
      min-height: 32px;
      color: $white;
      margin: 0;
      padding: 12px ($spacer * .5);
      @extend .clearfix;
      .fa
      {
        @extend .float-right;
        color: $white;
        margin-top: $spacer / 3;
        &.text-color
        {
          color: $text-color !important;
        }
      }
      code
      {
        @extend .float-right;
        margin: -3px 0 -3px ($spacer * .5);
      }
    }
    li:last-child
    {
      color: $text-color;
      .fa
      {
        color: $white;
      }
    }
  }
}
.color-black
{
  .color
  {background-color: $black;}
  .ich
  {background-color: $black-ich;}
  .light
  {background-color: $black-light;}
  .lighter
  {background-color: $black-lighter;}
  .ultralight
  {background-color: $black-ultralight;}
  .superlight
  {background-color: $black-superlight;}
}
.color-dark-gray
{
  .color
  {background-color: $dark-gray;}
  .ich
  {background-color: $dark-gray-ich;}
  .light
  {background-color: $dark-gray-light;}
  .lighter
  {background-color: $dark-gray-lighter;}
  .ultralight
  {background-color: $dark-gray-ultralight;}
  .superlight
  {background-color: $dark-gray-superlight;}
}
.color-gray
{
  .color
  {background-color: $gray;}
  .ich
  {background-color: $gray-ich;}
  .light
  {background-color: $gray-light;}
  .lighter
  {background-color: $gray-lighter;}
  .ultralight
  {background-color: $gray-ultralight;}
  .superlight
  {background-color: $gray-superlight;}
}
.color-light-gray
{
  li
  {
      color: $text-color !important;
  }
  .color
  {background-color: $light-gray;}
  .ich
  {background-color: $light-gray-ich;}
  .light
  {background-color: $light-gray-light;}
  .lighter
  {background-color: $light-gray-lighter;}
  .ultralight
  {background-color: $light-gray-ultralight;}
  .superlight
  {background-color: $light-gray-superlight;}
}
.color-ochre
{
  .dark
  {background-color: $ochre-dark;}
  .color
  {background-color: $ochre;}
  .ich
  {background-color: $ochre-ich;}
  .light
  {background-color: $ochre-light;}
  .lighter
  {background-color: $ochre-lighter;}
  .ultralight
  {background-color: $ochre-ultralight;}
}
.color-pink
{
  .dark
  {background-color: $pink-dark;}
  .color
  {background-color: $pink;}
  .ich
  {background-color: $pink-ich;}
  .light
  {background-color: $pink-light;}
  .lighter
  {background-color: $pink-lighter;}
  .ultralight
  {background-color: $pink-ultralight;}
}
.color-blue
{
  .dark
  {background-color: $blue-dark;}
  .color
  {background-color: $blue;}
  .ich
  {background-color: $blue-ich;}
  .light
  {background-color: $blue-light;}
  .lighter
  {background-color: $blue-lighter;}
  .ultralight
  {background-color: $blue-ultralight;}
}
.color-green
{
  .dark
  {background-color: $green-dark;}
  .color
  {background-color: $green;}
  .ich
  {background-color: $green-ich;}
  .light
  {background-color: $green-light;}
  .lighter
  {background-color: $green-lighter;}
  .ultralight
  {background-color: $green-ultralight;}
}
.color-v-alert
{
  .color
  {
    background-color: $red;
    color: $white !important;
  }
}
.color-v-success
{
  .color
  {
    background-color: $success;
    color: $white !important;
  }
}
